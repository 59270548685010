import { GetInventoryBatchResponse } from 'api/actions';
import { InventoryBatchTypeEnum, InventoryBatchUpdate } from 'api/resources';
import { InventoryEdits } from 'components';
import { UseInventoryTableSettingsArgs } from 'components/InventoryEditsTable/hooks/useInventoryTableSettings.hook';
import { UseInventoryTableTeamColumnsArgs } from 'components/InventoryEditsTable/hooks/useInventoryTableTeamColumns.hook';

export const getInitialTransferConfig = (batch: GetInventoryBatchResponse['data'], warehouseTeamId: string): UseInventoryTableSettingsArgs['initialTransferConfig'] => {
  if (batch.team.isWarehouse) {
    return undefined;
  }

  if(batch.type === InventoryBatchTypeEnum.picking) {
    return {
      out: warehouseTeamId,
      in: batch.team._id,
    };
  }

  return {
    out: batch.team._id,
    in: warehouseTeamId,
    autoFillBase: 'out',
  };
};

export const getTeamsStockMetaConfig = (initialTransferConfig: UseInventoryTableSettingsArgs['initialTransferConfig'], inventoryBatchType: InventoryBatchTypeEnum): UseInventoryTableTeamColumnsArgs['teamsStockMetaConfig'] => {
  if(!initialTransferConfig) {
    return undefined;
  }

  if(inventoryBatchType === InventoryBatchTypeEnum.picking) {
    return {
      [initialTransferConfig.in]: { overstocked: 'secondary' },
      [initialTransferConfig.out]: { baseline: 'secondary', needed: 'secondary', overstocked: 'secondary' },
    };
  }

  if(inventoryBatchType === InventoryBatchTypeEnum.overstockPicking) {
    return {
      [initialTransferConfig.in]: { baseline: 'secondary', needed: 'secondary', overstocked: 'secondary' },
      [initialTransferConfig.out]: { needed: 'secondary' },
    };
  }

  return undefined;
};

export const prepareInitialInventoryEdits = (batch: GetInventoryBatchResponse['data'], warehouseTeamId: string) => {
  return batch.updates.reduce((acc: InventoryEdits, update) => {
    return {
      ...acc,
      [update.product]: {
        [batch.type === InventoryBatchTypeEnum.overstockPicking ? warehouseTeamId : batch.team._id]: { value: update.quantity }
      },
    };
  }, {});
};

const getEditReviewQuantity = (batch: GetInventoryBatchResponse['data'], update: GetInventoryBatchResponse['data']['updates'][number], warehouseTeamId: string, inventoryEdits: InventoryEdits) => {
  const edit = inventoryEdits[update.product]?.[batch.type === InventoryBatchTypeEnum.overstockPicking ? warehouseTeamId : batch.team._id];
  const reviewQuantity = edit?.value ?? 0;

  return reviewQuantity;
};

export const prepareReviewInventoryEdits = (batch: GetInventoryBatchResponse['data'], warehouseTeamId: string, inventoryEdits: InventoryEdits, isManagerReview?: boolean) => {
  const batchProductUpdates = batch.updates
    .map((update) => {
      const reviewQuantity = getEditReviewQuantity(batch, update, warehouseTeamId, inventoryEdits);

      if (isManagerReview) {
        return {
          ...update,
          managerReviewQuantity: reviewQuantity
        };
      }

      return {
        ...update,
        reviewQuantity,
      };
    });

  const addedProductsUpdates = Object.entries(inventoryEdits)
    .filter(([ productId ]) => !batchProductUpdates.find(update => update.product === productId))
    .reduce((acc: InventoryBatchUpdate[], [ productId, productEdits ]) => {
      return Object.values(productEdits).reduce((acc, inventoryEdit) => {
        if (isManagerReview) {
          const update: InventoryBatchUpdate = {
            quantity: 0,
            reviewQuantity: 0,
            managerReviewQuantity: inventoryEdit.value,
            product: productId,
          };

          return [ ...acc, update ];
        }

        const update: InventoryBatchUpdate = {
          quantity: 0,
          reviewQuantity: inventoryEdit.value,
          product: productId,
        };

        return [ ...acc, update ];
      }, acc);
    }, []);

  return [ ...batchProductUpdates, ...addedProductsUpdates ];
};