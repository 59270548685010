import { useEventFeedback } from 'queries/eventFeedback';
import { AddEventFeedbackModal, EventFeedbackCard, PageContentContainer, SummativeEventFeedback, useEventContext } from 'components';
import { EventPageHeader } from 'pages/Event/components';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import { useCurrentUser } from 'contexts';
import { ReviewsOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';

export const EventFeedback = () => {
  const { event } = useEventContext();
  const { getIsMe } = useCurrentUser();
  const { data: eventFeedback = [] } = useEventFeedback(event._id);
  const [ addFeedbackOpen, setAddFeedbackOpen ] = useState(false);
  const addFeedbackDisabled = eventFeedback.some(feedback => getIsMe(feedback.user._id));

  return (
    <PageContentContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [
          { link: ROUTING_CONFIG.events, text: 'Events' },
          { link: DYNAMIC_ROUTES.event.createLink({ eventId: event._id }), text: event.name }
        ],
        current: 'Event Feedback'
      }}
      header={<EventPageHeader pageTitle="Event Feedback Page" />}
    >
      {addFeedbackOpen && <AddEventFeedbackModal onClose={() => setAddFeedbackOpen(false)} />}
      <SummativeEventFeedback feedback={eventFeedback} />
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Tooltip title={addFeedbackDisabled ? 'You have already left the feedback for this event. You can edit your form below.' : undefined}>
          <Box component="span">
            <Button
              variant="contained"
              onClick={() => setAddFeedbackOpen(true)}
              disabled={addFeedbackDisabled}
              endIcon={<ReviewsOutlined fontSize="small" />}
            >
              Leave Feedback
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Grid container spacing={2} mt={2}>
        {eventFeedback.map((feedback) =>(
          <Grid item sm={12} md={6} key={feedback._id}>
            <EventFeedbackCard eventFeedback={feedback} />
          </Grid>
        ))}
      </Grid>
    </PageContentContainer>
  );
};