import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BarChartOutlined, TimelineOutlined } from '@mui/icons-material';
import { AnalyticsChartTypeEnum } from '../types';

export type BarOrLineToggleProps = {
  chartType: AnalyticsChartTypeEnum;
  onChartTypeChange: (value: AnalyticsChartTypeEnum) => void;
};

export const BarOrLineToggle: React.FC<BarOrLineToggleProps> = ({ chartType, onChartTypeChange }) => {
  return (
    <ToggleButtonGroup value={chartType} onChange={(_, value) => value && onChartTypeChange(value)} exclusive size="small">
      <ToggleButton value={AnalyticsChartTypeEnum.bar}><BarChartOutlined fontSize="small" /></ToggleButton>
      <ToggleButton value={AnalyticsChartTypeEnum.line}><TimelineOutlined fontSize="small" /></ToggleButton>
    </ToggleButtonGroup>
  );
};