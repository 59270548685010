import { useEffect, useMemo, useState } from 'react';
import { analyticsTimePeriodEnumHelpers } from '../helpers';
import { AnalyticsTimePeriodEnum, ChartDateRangeType } from '../types';

export type UseAdaptiveTimePeriodProps = {
  dateRange?: ChartDateRangeType | null;
  initialTimePeriod?: AnalyticsTimePeriodEnum;
  timePeriodOptions?: AnalyticsTimePeriodEnum[];
};

export const useAdaptiveTimePeriod = ({ dateRange, timePeriodOptions, initialTimePeriod }: UseAdaptiveTimePeriodProps) => {
  const validTimePeriods = useMemo(() => {
    if (dateRange) {
      return analyticsTimePeriodEnumHelpers.getValidTimePeriodsForDateRange(dateRange);
    };

    return timePeriodOptions ?? analyticsTimePeriodEnumHelpers.enumValues;
  }, [ dateRange, timePeriodOptions ]);

  const [ timePeriod, setTimePeriod ] = useState<AnalyticsTimePeriodEnum>(initialTimePeriod ?? validTimePeriods[0]);

  const timePeriodInput = validTimePeriods.includes(timePeriod) ? timePeriod : validTimePeriods[0];

  useEffect(() => {
    if (timePeriodInput === timePeriod) {
      return;
    }

    setTimePeriod(timePeriodInput);
  }, [ timePeriod, timePeriodInput ]);

  return { timePeriod, setTimePeriod, validTimePeriods };
};