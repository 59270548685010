import { Box, Container, Divider } from '@mui/material';
import { analyticsTimePeriodEnumHelpers, JumpNav, PageContentContainer, PageContentResourceHeader, SalesTimelineChart, SectionCardsEditContextProvider } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useTeamOutletContext } from './Team.base';
import { useCurrentUser } from 'contexts';
import { EventsSection, InventorySection, OverviewSection } from './sections';
import { TeamPageSection } from './types';
import { DateService } from 'services';

export const Team = () => {
  const { team } = useTeamOutletContext();
  const { isAdmin } = useCurrentUser();

  return (
    <PageContentContainer
      maxWidth="lg"
      preventContainer
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.teams, text: 'Teams' } ],
        current: team.name,
      }}
      header={(
        <PageContentResourceHeader
          pageTitle="Team Page"
          title={team.name}
          stickyTabs
          tabs={(
            <JumpNav
              offsetTop={64}
              initialValue={TeamPageSection.OVERVIEW}
              tabs={[
                { label: 'Overview', sectionId: TeamPageSection.OVERVIEW },
                { label: 'Event', sectionId: TeamPageSection.EVENTS, hidden: team.isWarehouse },
                { label: 'Sales', sectionId: TeamPageSection.SALES, hidden: !isAdmin },
                { label: 'Inventory', sectionId: TeamPageSection.INVENTORY }
              ]}
            />
          )}
        />
      )}
    >

      <Box display="flex" flexDirection="column" gap={5} pt={5} pb={10}>
        <SectionCardsEditContextProvider>
          <Container maxWidth="lg">
            <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
              <OverviewSection />
              {!team.isWarehouse && <EventsSection />}
            </Box>
          </Container>
          {isAdmin && (
            <Box bgcolor="background.paper" id={TeamPageSection.SALES}>
              <Box bgcolor="primary.background">
                <Divider />
                <Container maxWidth="lg">
                  <Box py={5}>
                    <SalesTimelineChart
                      teamId={team._id}
                      timePeriodOptions={
                        analyticsTimePeriodEnumHelpers.getValidTimePeriodsForDateRange({
                          start: DateService.dayjs(team.createdAt),
                          end: DateService.dayjs().endOf('day')
                        })
                      }
                    />
                  </Box>
                </Container>
                <Divider />
              </Box>
            </Box>
          )}
          <Container maxWidth="lg">
            <InventorySection />
          </Container>
        </SectionCardsEditContextProvider>
      </Box>

    </PageContentContainer>
  );
};