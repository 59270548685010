import { EventNoteOutlined, ExpandLess, ExpandMore, PlaceOutlined } from '@mui/icons-material';
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';
import { EventStartDateAndEndDate, GetEventsResponse } from 'api/actions';
import { Event, PlaceDefaultEnum, Team } from 'api/resources';
import { EditableImage } from 'components/EditableImage';
import { PlaceDistanceAndDurationContent } from 'components/PlaceDistanceAndDurationContent';
import { useMemo, useState } from 'react';
import { DateService } from 'services';

export type EventContentData = {
  event: {
    _id: Event['_id'];
    name: Event['name'];
    team?: Pick<Team, 'name'>;
    place: GetEventsResponse['data'][number]['place'];
    year: Event['year'];
    iconUrl: Event['iconUrl'];
  };

  startDate?: EventStartDateAndEndDate | null;
  endDate?: EventStartDateAndEndDate | null;
};

export type EventContentProps = {
  data: EventContentData;
  children?: React.ReactNode | ((isExpanded: boolean) => React.ReactNode);
  collapsable?: boolean;
  actions?: React.ReactNode;
  disableActionsDivider?: boolean;
  disablePadding?: boolean;
  showPlaceDistanceAndDurationContent?: boolean;
};

export const EventContent: React.FC<EventContentProps> = props => {
  const [ isExpanded, setIsExpanded ] = useState(!props.collapsable);
  const { event, startDate, endDate } = props.data;

  const dateRange = useMemo(() => {
    if (startDate && endDate) {
      return DateService.getFormattedDateRange(DateService.dayjsTz(startDate.dateAsUtc), DateService.dayjsTz(endDate.dateAsUtc), false);
    }

    return null;
  }, [ endDate, startDate ]);

  const children = typeof props.children === 'function' ? props.children(isExpanded) : props.children;

  return (
    <Box width="100%" pb={isExpanded ? undefined : 1}>

      <Box p={props.disablePadding ? undefined : 2} pb={children ? 1 : undefined}>
        <Box position="relative" display="flex">
          <Box flex={1}>
            <Typography component="span" fontSize="1.15rem" lineHeight={1} fontWeight={500}>{event.name}&nbsp;</Typography>
            <Typography component="span">{event.year}</Typography>
          </Box>

          {props.collapsable && (
            <Box pl={4}>
              <Box
                position="absolute"
                right={-4}
                top="50%"
                sx={{ transform: 'translateY(-50%)' }}
              >
                <IconButton size="small" onClick={() => setIsExpanded(p => !p)}>
                  {isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                </IconButton>
              </Box>
            </Box>
          )}

        </Box>
        <Collapse in={isExpanded} unmountOnExit>
          <Box display="flex" gap={2} mt={1}>
            <EditableImage
              width={props.showPlaceDistanceAndDurationContent ? '120px' : '100px'}
              height={props.showPlaceDistanceAndDurationContent ? '120px' : '100px'}
              onUpload={()=>{}}
              imageUrl={event.iconUrl}
              disabledEditable
            />
            <Box>
              <Typography mt={0.5} display="flex" alignItems="center"><PlaceOutlined color="error" fontSize="small" />{event.place.address}</Typography>
              {props.showPlaceDistanceAndDurationContent && (
                <PlaceDistanceAndDurationContent
                  fromLabel="Studio"
                  toLabel="Event"
                  placeMatrix={event.place.fromStudioMatrix}
                  routeDetails={{
                    fromAddress: PlaceDefaultEnum.studio,
                    toAddress: event.place.address,
                  }}
                />
              )}
              <Typography display="flex" alignItems="center"><EventNoteOutlined fontSize="small" />{dateRange}</Typography>
              <Typography color={event.team ? 'primary' : 'warning.main'}>{event.team?.name ?? 'Team Unassigned'}</Typography>
            </Box>
          </Box>
        </Collapse>
      </Box>
      {children}

      {props.actions && (
        <Collapse in={isExpanded} unmountOnExit>
          <Box mt={1}>
            {!props.disableActionsDivider && <Divider />}
            <Box>
              {props.actions}
            </Box>
          </Box>
        </Collapse>
      )}

    </Box>
  );
};