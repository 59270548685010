import { authAxiosRequest } from 'api/axios';
import { GetEventDateAddStaffUserOptions } from '../eventDate';
import { EventDate, EventForResourceTypeEnum, User } from 'api/resources';
import { GetEventsResponse } from '../event';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { GetUserAvailabilityResponse } from '../user';

export type GetStaffSchedulingForEventInput = {
  eventId: string;
};

export type GetStaffSchedulingForEventResponse = {
  data: {
    [eventDateId: string]: {
      [userId: string]: Omit<GetEventDateAddStaffUserOptions['data'][number], 'conflictEvent'> & {
        conflictEvent?: {
          name: string;
          _id: string;
        };
      };
    };
  };
};

export const getStaffSchedulingForEvent = (data: GetStaffSchedulingForEventInput) => {
  return authAxiosRequest<GetStaffSchedulingForEventResponse>('/api/custom/get_staff_scheduling_for_event', { method: 'POST', data });
};

export type GetUpcomingEventsHashResponse = {
  data: {
    [resourceId: string]: {
      event: GetEventsResponse['data'][number];
      type: EventForResourceTypeEnum;
    }[];
  };
};

export type GetUpcomingEventsForTeamsHashInput = {
  teamId?: string;
  limit?: number;
};

export const getUpcomingEventsForTeamsHash = (data?: GetUpcomingEventsForTeamsHashInput) => {
  return authAxiosRequest<GetUpcomingEventsHashResponse>('/api/custom/get_upcoming_events_for_teams_hash', { method: 'POST', data });
};

export type GetUpcomingEventsForVehiclesHashInput = {
  vehicleId?: string;
  limit?: number;
};

export const getUpcomingEventsForVehiclesHash = (data?: GetUpcomingEventsForVehiclesHashInput) => {
  return authAxiosRequest<GetUpcomingEventsHashResponse>('/api/custom/get_upcoming_events_for_vehicles_hash', { method: 'POST', data });
};

export type GetUsersAvailabilitySetHashResponse = {
  data: {
    [userId: string]: {
      title: string;
      label: string;
      severity: 'error' | 'warning' | 'success';
      availabilitySlots: GetUserAvailabilityResponse['data'];
    };
  };
};

export const getUsersAvailabilitySetHash = () => {
  return authAxiosRequest<GetUsersAvailabilitySetHashResponse>('/api/custom/get_users_availability_set_hash');
};

export type GetEventsForDashboardResponse = {
  data: {
    feedbackReminder: GetEventsResponse['data'];
    upcomingAttendEvents: (GetEventsResponse['data'][number] & {
      upcomingEventDate: ResourceWithPopulated<EventDate, {
        staff: ResourceWithPopulated<EventDate['staff'][number], { user: PickFromResource<User, 'name'> }>[];
      }>;
    })[];
    requestedEvents: (GetEventsResponse['data'][number] & {
      statusDates: ResourceWithPopulated<EventDate, {
        staff: ResourceWithPopulated<EventDate['staff'][number], {
          user: PickFromResource<User, 'name'>;
        }>[];
      }>[];
    })[];
    selfRequestedEvents: (GetEventsResponse['data'][number] & {
      statusDates: ResourceWithPopulated<EventDate, {
        staff: ResourceWithPopulated<EventDate['staff'][number], {
          user: PickFromResource<User, 'name'>;
        }>[];
      }>[];
    })[];
    calendarEvents: GetEventsResponse['data'];
  };
};

export const getEventsForDashboard = () => {
  return authAxiosRequest<GetEventsForDashboardResponse>('/api/custom/get_events_for_dashboard', { method: 'POST' });
};