import { Box } from '@mui/material';
import { useEventContext } from 'components/Event/event.context';
import { EventSalesTable } from 'components/Table';
import React from 'react';

export const EventPreviewContentSales = () => {
  const { eventSales, loading } = useEventContext();

  return (
    <Box p={2} height="calc(100vh - 171px)">
      <EventSalesTable sales={eventSales} loading={loading} />
    </Box>
  );
};
