import { Typography } from '@mui/material';
import { PageContentContainer, PageContentHeader } from 'components';

export const Dashboard = () => {
  // const { isAuthorizedEmployee } = useCurrentUser();

  // const initialTab = useMemo(() => {
  //   if (isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false)) {
  //     return DashboardPageSection.EVENTS_MANAGEMENT;
  //   }
  //   if (isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false)) {
  //     return DashboardPageSection.PRODUCTION;
  //   }
  //   if (isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false)) {
  //     return DashboardPageSection.EVENTS;
  //   }

  //   return null;
  // }, [ isAuthorizedEmployee ]);

  // const tabs = useMemo(() => initialTab && (
  //   <JumpNav
  //     initialValue={initialTab}
  //     offsetTop={64}
  //     tabs={[
  //       { label: 'Events Management', sectionId: DashboardPageSection.EVENTS_MANAGEMENT, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false) },
  //       { label: 'Production', sectionId: DashboardPageSection.PRODUCTION, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false) },
  //       { label: 'Events', sectionId: DashboardPageSection.EVENTS, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false) },
  //     ]}
  //   />
  // ), [ initialTab, isAuthorizedEmployee ]);

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Dashboard"
          sticky
        />
      )}
    >
      <Typography>This page is temporarily unavailable as we are making improvements.</Typography>
      {/* <Box display="flex" flexDirection="column" gap={5}>
         {isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false) && <EventsManagementOverview />}
         {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false) && <ProductionOverview />}
         {isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false) && <EventsOverview />}
       </Box> */}
    </PageContentContainer>
  );
};

