import { Box, Card, Typography, useTheme } from '@mui/material';
import { BarChart, BarSeriesType } from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/internals';
import { GetSalesAnalyticsResponse } from 'api/actions';
import { DateRange } from 'components';
import { useSalesAnalytics } from 'queries';

export type SalesChartProps = {
  dateRange: DateRange;
  compareDateRange?: DateRange | null;
  type: keyof GetSalesAnalyticsResponse['data'];
};

const getLabel = (type: SalesChartProps['type']) => {
  switch (type) {
    case 'byUser':
      return 'Sales by User';
    case 'byProduct':
      return 'Sales by Product';
    case 'byEvent':
      return 'Sales by Event';
    default:
      return 'Sales';
  }
};

export const SalesChart: React.FC<SalesChartProps> = ({ dateRange, type = 'byUser', compareDateRange }) => {
  const theme = useTheme();
  const { data } = useSalesAnalytics({
    start: dateRange.start.toISOString(),
    end: dateRange.end.endOf('day').toISOString(),
    compareStart: compareDateRange?.start.toISOString(),
    compareEnd: compareDateRange?.end.endOf('day').toISOString(),
  });

  const dataset = data?.[type] ?? [];

  const series: MakeOptional<BarSeriesType, 'type'>[] = [
    {
      id: 'current',
      label: 'Current',
      dataKey: 'count',
      highlightScope: {
        highlight: 'series',
        fade: 'global'
      }
    },
  ];

  if (compareDateRange) {
    series.push({
      id: 'comparison',
      label: 'Comparison',
      dataKey: 'compareCount',
      highlightScope: {
        highlight: 'series',
        fade: 'global'
      }
    });
  }

  return (
    <Card variant="outlined">
      <Box p={2} display="flex" justifyContent="space-between" >
        <Box display="flex" columnGap={3} rowGap={1} flexWrap="wrap">
          <Typography variant="h6">{getLabel(type)}</Typography>
        </Box>
      </Box>

      <BarChart
        slotProps={{ legend: { hidden: true } }}
        colors={[ theme.palette.primary.main, theme.palette.secondary.main ]}
        yAxis={[
          {
            scaleType: 'band',
            dataKey: 'label',
            valueFormatter(value, context) {
              if (context.location === 'tick') {
                if (value.length > 15) {
                  return `${value.slice(0, 15)}...`;
                }
              }

              return value;
            },
            tickLabelStyle: {
              fontSize: 10
            }
          }
        ]}
        dataset={dataset}
        series={series}
        layout="horizontal"
        grid={{ vertical: true }}
        height={400}
        borderRadius={8}
        margin={{ top: 8, bottom: 32, left: 100, right: 24 }}
        bottomAxis={{
          tickLabelStyle: {
            fontSize: 11
          }
        }}
      />
    </Card>
  );
};