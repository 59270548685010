import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSalesTimelineAnalytics } from 'queries';
import { currencyFormatterWithoutDecimals, DateService } from 'services';
import { GetSalesTimelineAnalyticsInput } from 'api/actions';
import { AnalyticsChartTypeEnum, AnalyticsTimePeriodEnum, ChartDateRangeType } from './types';
import { TimelineChart } from './TimelineChart';
import { useAdaptiveTimePeriod } from './hooks/use-adaptive-time-period';

export type SalesTimelineChartProps = {
  dateRange?: ChartDateRangeType | null;
  compareDateRange?: ChartDateRangeType | null;
  productId?: string;
  userId?: string;
  eventDateId?: string;
  teamId?: string;

  initialTimePeriod?: AnalyticsTimePeriodEnum;
  timePeriodOptions?: AnalyticsTimePeriodEnum[];
};

export const SalesTimelineChart: React.FC<SalesTimelineChartProps> = ({ dateRange, compareDateRange, productId, userId, eventDateId, teamId , initialTimePeriod, timePeriodOptions }) => {
  const { timePeriod, setTimePeriod, validTimePeriods } = useAdaptiveTimePeriod({ dateRange, initialTimePeriod, timePeriodOptions });
  const [ chartType, setChartType ] = useState<AnalyticsChartTypeEnum>(AnalyticsChartTypeEnum.bar);

  const currentInput: GetSalesTimelineAnalyticsInput['series'][number] = {
    id: 'current',
    productId,
    userId,
    eventDateId,
    teamId,
  };

  if (dateRange) {
    currentInput.start = dateRange.start.toISOString();
    currentInput.end = dateRange.end.endOf('day').toISOString();
  }

  const seriesInput = [ currentInput ];

  let compareInput: GetSalesTimelineAnalyticsInput['series'][number] | null = null;

  if (compareDateRange) {
    compareInput = {
      id: 'comparison',
      start: compareDateRange.start.toISOString(),
      end: compareDateRange.end.endOf('day').toISOString(),
      productId,
      userId,
      eventDateId,
      teamId,
    };

    seriesInput.push(compareInput);
  }

  const { data: saleAnalyticsSeries = [], isLoading } = useSalesTimelineAnalytics({
    timePeriod: timePeriod,
    timezone: DateService.dayjs.tz.guess(),
    series: seriesInput,
  });

  return (
    <TimelineChart
      title="Sales"
      chartType={chartType}
      setChartType={setChartType}
      timePeriod={timePeriod}
      setTimePeriod={setTimePeriod}
      timePeriodOptions={validTimePeriods}
      series={saleAnalyticsSeries}
      getSeriesData={series => series.data.map(d => d.count)}
      isLoading={isLoading}
      estSeriesLength={seriesInput.length}
      getLengendChipContent={s => (
        <Box ml={0.5} display="flex" alignItems="center" gap={1}>
          <Typography variant="body2" color="text.secondary" fontWeight={500}>{s.data.reduce((acc, d) => acc + d.count, 0)} sales</Typography>
          <Typography variant="body2" color="text.secondary">{currencyFormatterWithoutDecimals.format(s.data.reduce((acc, d) => acc + d.revenue, 0))}</Typography>
        </Box>
      )}
      getTooltipContent={data => [
        {
          content: <Typography variant="body2" fontWeight={500}>{data.count} sales</Typography>,
        },
        {
          content: <Typography variant="body2">{currencyFormatterWithoutDecimals.format(data.revenue)}</Typography>,
        }
      ]}
    />
  );
};