import { Provider } from 'react-redux';
import { store } from 'app/store';
import { useAppSelector } from 'app/hooks';
import {
  BrowserRouter, Navigate, Outlet, Route, Routes, useLocation,
} from 'react-router-dom';
import {
  ProductionSchedule,
  InventoryAdjustmentsBase,
  InventoryAdjustments,
  InventoryBatches,
  InventoryBatchBase,
  InventoryBatch,
  Baselines,
  ProductUpdateLogs,
  ProductBase,
  Product,
  ProductNew,
  Retail,
  EventBase,
  EventDates,
  EventScheduling,
  EventFeedback,
  EventDate,
  EventSales,
  EventNew,
  EventOrganizers,
  EventOrganizerBase,
  EventOrganizer,
  EventOrganizerNew,
  SalesLog,
  Storefronts,
  SaleBase,
  Sale,
  LogLiveSale,
  LoggingPortalBase,
  LoggingPortal,
  LogSale,
  Teams,
  TeamBase,
  Team,
  Vehicles,
  Vehicle,
  Profile,
  Users,
  User,
  NoRoute404,
  Event,
  Orders,
  Order,
  OrderBase,
  Production,
  VehicleBase,
} from 'pages';

import { AlertSnackbarProvider, ErrorBoundary, LightLayout, Loading, MainLayout } from 'components';
import { MainLayoutProvider, useCurrentUser } from 'contexts';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { MainThemeProvider } from 'services/theme/withTheme';
import { Login } from 'auth/pages';
import './main.css';
import { UserNew } from 'pages/User/UserNew.page';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { EventsManagement } from 'pages/EventsManagement/EventsManagement.page';
import { useMe } from 'app/auth/use-me.hook';
import { UserEmployeeRoleEnum } from 'api/resources';
import { AccessDenied403 } from 'pages/AccessDenied403';
import { lazy, Suspense } from 'react';
import { InProduction } from 'pages/Production/pages/Production/InProduction';

const lightPaths: string[] = [];
const noLayoutPaths: string[] = [ ROUTING_CONFIG.loggingPortalBase ];

const MainPrivateOutlet = () => {
  const { pathname } = useLocation();
  const auth = useAppSelector((state) => state.auth);

  const hasToken = auth.accessToken;

  if (hasToken) {
    const isNoLayout = noLayoutPaths.findIndex((noLayoutPath) => pathname.includes(noLayoutPath)) !== -1;

    if (isNoLayout) {
      return <Outlet />;
    }

    const isLight = lightPaths.findIndex((lightPath) => pathname.includes(lightPath)) !== -1;

    if (isLight) {
      return (
        <LightLayout>
          <Outlet />
        </LightLayout>
      );
    }

    return (
      <MainLayoutProvider>
        <MainLayout>
          <ErrorBoundary pathname={pathname}>
            <Outlet />
          </ErrorBoundary>
        </MainLayout>
      </MainLayoutProvider>
    );
  }

  return <Navigate to={ROUTING_CONFIG.login} />;
};

const LoginOutlet = () => {
  const auth = useAppSelector((state) => state.auth);
  const hasToken = auth.accessToken;

  if (!hasToken) {
    return <Outlet />;
  }

  return <Navigate to={ROUTING_CONFIG.dashboard} />;
};

type ProtectedRouteProps = {
  authorizedEmployeeRoles?: UserEmployeeRoleEnum[];
  adminOnly?: boolean;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ authorizedEmployeeRoles, adminOnly, children }) => {
  const { isAdmin, isAuthorizedEmployee } = useCurrentUser();
  const isAuthorized = isAdmin || ((!authorizedEmployeeRoles || isAuthorizedEmployee(authorizedEmployeeRoles)) && !adminOnly);

  if(!isAuthorized) {
    return <Navigate to="/403" replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const Routing = () => {
  const { loading } = useMe();

  if (loading) {
    return <Loading />;
  }

  const Dashboard = lazy(() => import('pages/Dashboard'));
  const Analytics = lazy(() => import('pages/Analytic'));
  const Categories = lazy(() => import('pages/Products/pages/Categories'));
  const Genres = lazy(() => import('pages/Products/pages/Genres'));
  const ProductList = lazy(() => import('pages/Products/pages/ProductsList'));

  return (
    <BrowserRouter>
      <Suspense fallback={<MainLayoutProvider><MainLayout><Loading /></MainLayout></MainLayoutProvider>}>
        <Routes>
          <Route
            path={ROUTING_CONFIG.dashboard}
            element={<MainPrivateOutlet />}
          >
            <Route index element={<Dashboard />} />
            <Route path={ROUTING_CONFIG.analytics} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]}><Analytics /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.categories} element={<ProtectedRoute adminOnly><Categories /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.genres} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.wholesaleManager ]}><Genres /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.productList} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember, UserEmployeeRoleEnum.wholesaleManager ]}><ProductList /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.inventoryAdjustments} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]}><InventoryAdjustmentsBase /></ProtectedRoute>}>
              <Route index element={<InventoryAdjustments />} />
            </Route>

            <Route path={ROUTING_CONFIG.production} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]}><Production /></ProtectedRoute>}>
              <Route index element={<Navigate to={ROUTING_CONFIG.productionSchedule} replace />} />
              <Route path={ROUTING_CONFIG.productionSchedule} element={<ProductionSchedule />} />
              <Route path={ROUTING_CONFIG.inProduction} element={<InProduction />} />
              <Route path={ROUTING_CONFIG.productUpdateLogs} element={<ProductUpdateLogs />} />
              <Route path={ROUTING_CONFIG.inventoryBatches} element={<InventoryBatches />} />
            </Route>
            <Route path={ROUTING_CONFIG.inventoryBatch} element={<InventoryBatchBase />}>
              <Route index element={<InventoryBatch />} />
            </Route>
            <Route path={ROUTING_CONFIG.baselines} element={<ProtectedRoute adminOnly><Baselines /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.genres} element={<ProtectedRoute adminOnly><Genres /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.product} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember, UserEmployeeRoleEnum.wholesaleManager ]}><ProductBase /></ProtectedRoute>}>
              <Route index element={<Product />} />
            </Route>
            <Route path={ROUTING_CONFIG.productNew} element={<ProtectedRoute adminOnly><ProductNew /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.retail} element={<Retail />} />
            <Route path={ROUTING_CONFIG.events} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><EventsManagement /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.eventNew} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><EventNew /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.event} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember, UserEmployeeRoleEnum.juniorTeamMember, UserEmployeeRoleEnum.eventManager ]}><EventBase /></ProtectedRoute>}>
              <Route index element={<Event />} />
              <Route path={ROUTING_CONFIG.eventDates}>
                <Route index element={<EventDates />} />
                <Route path={ROUTING_CONFIG.eventDate} element={<EventDate />} />
              </Route>
              <Route path={ROUTING_CONFIG.eventScheduling} element={<EventScheduling />} />
              <Route path={ROUTING_CONFIG.eventFeedback} element={<EventFeedback />} />
              <Route path={ROUTING_CONFIG.eventScheduling} element={<EventScheduling />} />
              <Route path={ROUTING_CONFIG.eventSales} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><EventSales /></ProtectedRoute>} />
            </Route>
            <Route path={ROUTING_CONFIG.eventOrganizers} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><EventOrganizers /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.eventOrganizerNew} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><EventOrganizerNew /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.eventOrganizer} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager, UserEmployeeRoleEnum.teamLead ]}><EventOrganizerBase /></ProtectedRoute>}>
              <Route index element={<EventOrganizer />} />
            </Route>
            <Route path={ROUTING_CONFIG.storefronts} element={<ProtectedRoute adminOnly><Storefronts /></ProtectedRoute>} />

            <Route path={ROUTING_CONFIG.salesLog} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager ]}><SalesLog /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.sale} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager ]}><SaleBase /></ProtectedRoute>}>
              <Route index element={<Sale />} />
            </Route>

            <Route path={ROUTING_CONFIG.orders} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager ]}><Orders /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.order} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.productionManager ]}><OrderBase /></ProtectedRoute>}>
              <Route index element={<Order />} />
            </Route>

            <Route path={ROUTING_CONFIG.logLiveSale} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ]}><LogLiveSale /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.loggingPortal} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ]}><LoggingPortalBase /></ProtectedRoute>}>
              <Route index element={<LoggingPortal />} />
              <Route path={ROUTING_CONFIG.loggingPortalLogSale} element={<LogSale />} />
            </Route>

            <Route path={ROUTING_CONFIG.teams} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><Teams /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.team} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><TeamBase /></ProtectedRoute>}>
              <Route index element={<Team />} />
            </Route>

            <Route path={ROUTING_CONFIG.vehicles} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><Vehicles /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.vehicle} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><VehicleBase /></ProtectedRoute>}>
              <Route index element={<Vehicle />} />
            </Route>
            <Route path={ROUTING_CONFIG.profile} element={<Profile />} />
            <Route path={ROUTING_CONFIG.users} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><Users /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.user} element={<ProtectedRoute authorizedEmployeeRoles={[ UserEmployeeRoleEnum.eventManager ]}><User /></ProtectedRoute>} />
            <Route path={ROUTING_CONFIG.userNew} element={<ProtectedRoute adminOnly><UserNew /></ProtectedRoute>} />
            <Route path="/403" element={<AccessDenied403 />} />
            <Route path="*" element={<NoRoute404 />} />
          </Route>
          <Route path={ROUTING_CONFIG.login} element={<LoginOutlet />}>
            <Route index element={<Login />} />
          </Route>
          <Route path="*" element={<Navigate to={ROUTING_CONFIG.login} replace />} />

        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst'
    }
  }
});

export const Main = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <AlertSnackbarProvider>
          <Provider store={store}>
            <MainThemeProvider>
              <Routing />
            </MainThemeProvider>
          </Provider>
        </AlertSnackbarProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};
