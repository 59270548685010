import { EventDatePageSection, EventDatePageSectionRowId, EventDateSectionProps, ProgressBar, RadioGroupYesNoBoolean, SectionCard, SectionCardRow, TextInput, useEventContext } from 'components';
import React, { useState } from 'react';
import { updateEventDate } from 'api/actions';
import { EventDateStaffStatusEnum, UserEmployeeRoleEnum } from 'api/resources';
import { processFormValueUpdate } from 'helpers';
import { object, number } from 'yup';
import { EventDateSectionCardRowEditable } from '../components/EventDateSectionCardRowEditable.component';
import { useUsersAvailabilityForEventDate } from 'queries/user';
import { DateService } from 'services';
import { yesNoSchema } from 'types';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { formSx } from 'styles';
import { Add } from '@mui/icons-material';
import { useEventDateAddStaffUserOptions } from 'queries/eventDate';
import { AddStaffCard, STAFF_CARD_DIMENSIONS, StaffCard } from '../components';
import { useCurrentUser } from 'contexts';
import { getWorkdayTotal } from 'helpers/event-date-times';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { DYNAMIC_ROUTES } from 'constants/routing-config';

export const StaffSection: React.FC<EventDateSectionProps> = ({ eventDate }) => {
  const navigate = useNavigate();
  const { event } = useEventContext();
  const currentUser = useCurrentUser();
  const { data: availabilityHash = {} } = useUsersAvailabilityForEventDate(eventDate._id);
  const { data: userOptionsData = [] } = useEventDateAddStaffUserOptions(eventDate._id);
  const [ showAddStaffForm, setShowAddStaffForm ] = useState(false);

  const isInPast = DateService.checkIfDateIsInPast(DateService.dayjsTz(eventDate.dateAsUtc));
  const teamMembers = eventDate.staff.sort((a) => a.isTeamLead ? -1 : 1);
  const staffConfirmed = eventDate.staff.reduce((r, staff) => staff.status === EventDateStaffStatusEnum.confirmed ? r + 1 : r, 0);
  const staffNeeded = eventDate.staffNeeded ?? 0;
  const showAddButton = !isInPast && ((event.teamManager?._id === currentUser._id || currentUser.isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ]))
    || !eventDate.staff.some(staff => staff.user._id === currentUser._id));
  const { workdayTotalInMinutes } = getWorkdayTotal({ event, eventDate });

  return (
    <SectionCard
      title="Staff"
      id={EventDatePageSection.STAFF}
      actions={<Button size="small" variant="outlined" onClick={() => navigate(DYNAMIC_ROUTES.eventScheduling.createLink({ eventId: event._id }))}>Manage Scheduling</Button>}
    >
      <EventDateSectionCardRowEditable
        title="Staff needed"
        rowId={EventDatePageSectionRowId.STAFF_NEEDED}
        formikProps={{
          initialValues: { staffNeeded: eventDate.staffNeeded ?? 0, teamLeadNeeded: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventDate.teamLeadNeeded) },
          onSubmit: (values) => updateEventDate(eventDate._id, { staffNeeded: processFormValueUpdate.number(values.staffNeeded), teamLeadNeeded: processFormValueUpdate.yesNo(values.teamLeadNeeded) }),
          validationSchema: object({ staffNeeded: number().min(0, 'Staff needed cannot be less than 0').default(0), teamLeadNeeded: yesNoSchema })
        }}
        form={(
          <Box sx={formSx.formGroup}>
            <TextInput
              label="Staff Needed (including team lead, if applicable)"
              name="staffNeeded"
              type="number"
              fullWidth
            />
            <RadioGroupYesNoBoolean label="Is Team Lead needed?" name="teamLeadNeeded" row />
          </Box>
        )}
      >
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="body2">Confirmed: <Box component="span" fontWeight={700}>{staffConfirmed}</Box></Typography>
          <Typography variant="body2">Needed: <Box component="span" fontWeight={700}>{staffNeeded}</Box></Typography>
        </Box>
        <ProgressBar currentAmount={staffConfirmed} expectedAmount={staffNeeded} />
      </EventDateSectionCardRowEditable>
      <SectionCardRow title="Staff" bgcolor={showAddStaffForm ? 'primary.background' : undefined}>
        <Box display="flex" gap={1} alignItems="flex-start" flexWrap="wrap" my={1}>
          {showAddStaffForm ? (
            <AddStaffCard eventDateId={eventDate._id} onExit={() => setShowAddStaffForm(false)} userOptionsData={userOptionsData} workdayTotalInMinutes={workdayTotalInMinutes} />
          ) : (showAddButton && (
            <Button
              variant="outlined"
              color="unknown"
              sx={{
                ...STAFF_CARD_DIMENSIONS,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 0,
              }}
              onClick={() => setShowAddStaffForm(true)}
            >
              <Add fontSize="large" />
            </Button>
          ))}
          {teamMembers.map(staff => (
            <StaffCard
              key={staff._id}
              eventDateId={eventDate._id}
              workdayTotalInMinutes={workdayTotalInMinutes}
              staff={staff}
              availability={availabilityHash[staff.user._id]}
              disabled={isInPast || showAddStaffForm}
            />
          ))}
        </Box>
      </SectionCardRow>
    </SectionCard>
  );
};