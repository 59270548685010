import { EventContextProvider, Loading } from 'components';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { useEvent, useSalesForEvent } from 'queries';
import { QUERY_KEY } from 'queries/query-keys';
import { Navigate, Outlet } from 'react-router-dom';

const EventBaseRaw: React.FC<{eventId: string}> = ({ eventId }) => {
  const { data: event, isInitialLoading: eventLoading } = useEvent(eventId, { retry: false });
  const { data: eventSales = [], isInitialLoading: eventSalesLoading } = useSalesForEvent(event?._id ?? '', !!event);

  if (eventLoading) {
    return <Loading />;
  }

  if (!event) {
    return <Navigate to={ROUTING_CONFIG.events} replace />;
  }

  return (
    <EventContextProvider event={event} eventSales={eventSales} loading={eventLoading || eventSalesLoading} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT(event._id) })}>
      <Outlet />
    </EventContextProvider>
  );
};

export const EventBase = () => {
  const { eventId } = DYNAMIC_ROUTES.event.useParams();

  if (!eventId) {
    return <Navigate to={ROUTING_CONFIG.events} replace />;
  }

  return <EventBaseRaw eventId={eventId} />;
};
