import React, { useContext } from 'react';
import { EventPreviewTypeEnum, EventsFullCalendar, FullCalendarEventContentContainer, UseColorCodeOutput, UseFilterOutput, useEventPreviewContext, useSpanEvents } from 'components';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { GetEventsResponse } from 'api/actions';
import { ShowTheseFieldsEventContent, eventsShowTheseFieldsGroupEnumHelpers } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction';
import { useShowTheseFields } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/useShowTheseFields.hook';
import { showTheseFieldsConfig } from './showTheseFields';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';
import { ResetAllActionContent } from 'components/FullCalendar/CalendarSidebarAction/ShowTheseFieldsAction/ResetAllActionContent.component';
import { eventColorCodeConfigHash, EventColorCodeOptionType } from '../../EventsCalendar.page';

export type EventsCalendarModalViewType = 'colorCode' | 'showTheseFields';
export type ApplicationsCalendarProps = UseFilterOutput<GetEventsResponse['data'][number]> & UseColorCodeOutput<EventColorCodeOptionType>;

export const EventsCalendar: React.FC<ApplicationsCalendarProps> = props => {
  const nodeId = EventsManagementTreeViewNodeIdEnum.eventsCalendar;
  const { events } = useContext(EventsManagementPageContext);
  const { setOpenEvent } = useEventPreviewContext();

  const { sidebarActionConfig: showTheseFieldsSidebarActionConfig, modal: showTheseFieldsModal, showTheseFields, onResetShowTheseFields } = useShowTheseFields({
    id: nodeId,
    config: showTheseFieldsConfig,
    defaultShowTheseFields: [ 'team', 'staff' ],
    groupEnumHelpers: eventsShowTheseFieldsGroupEnumHelpers,
  });
  const {
    spanEvents,
    setSpanEvents,
    sidebarActionConfig: spanEventsSidebarActionConfig
  } = useSpanEvents({ id: nodeId });

  const { filterFunc, modal: filtersModal, onResetFilters, sidebarActionConfig: filterSidebarActionConfig } = props;

  const onResetAll = () => {
    setSpanEvents(true);
    onResetFilters();
    props.setColorCodeOption('acceptanceStatus');
    onResetShowTheseFields();
  };

  return (
    <>
      {props.colorCodeModal}
      {showTheseFieldsModal}
      {filtersModal}

      <EventsFullCalendar
        id={nodeId}
        events={events}
        filter={event => filterFunc(event.extendedProps?.event._id || '')}
        spanEvents={spanEvents}
        onEventClick={(eventId) => setOpenEvent(eventId, EventPreviewTypeEnum.MAIN)}
        getEventContent={arg => {
          const { event, eventDate, group } = arg.event.extendedProps;

          const backgroundFunc = props.colorCodeOption ? eventColorCodeConfigHash[props.colorCodeOption].getColorKeyConfig({ event, eventDate }).background : undefined;

          return (
            <FullCalendarEventContentContainer
              isStart={arg.isStart}
              isEnd={arg.isEnd}
              backgroundFunc={backgroundFunc ?? (theme => theme.palette.primary.main)}
            >
              <ShowTheseFieldsEventContent
                showTheseFieldsConfig={showTheseFieldsConfig}
                showTheseFields={showTheseFields}
                title={event.name}
                extra={{ event }}
                eventDate={eventDate}
                group={group}
              />
            </FullCalendarEventContentContainer>
          );
        }}
        sidebarActionsConfig={[
          { content: <ResetAllActionContent onResetAll={onResetAll} /> },
          spanEventsSidebarActionConfig,
          filterSidebarActionConfig,
          props.colorCodeSidebarActionConfig,
          showTheseFieldsSidebarActionConfig
        ]}
      />
    </>
  );
};