import { EnumHelpers } from 'helpers/enums/types';
import { DateObjectType, DateService } from 'services';

export type DateRange = {
  start: DateObjectType;
  end: DateObjectType;
};

export const enum DateRangeShortcutEnum {
  today = 'today',
  yesterday = 'yesterday',
  last_7_days = 'last_7_days',
  last_30_days = 'last_30_days',
  last_90_days = 'last_90_days',
  last_365_days = 'last_365_days',
  last_month = 'last_month',
  last_6_month = 'last_6_month',
  last_12_month = 'last_12_month',
  last_year = 'last_year'
}

const enumValues: DateRangeShortcutEnum[] = [
  DateRangeShortcutEnum.today,
  DateRangeShortcutEnum.yesterday,
  DateRangeShortcutEnum.last_7_days,
  DateRangeShortcutEnum.last_30_days,
  DateRangeShortcutEnum.last_90_days,
  DateRangeShortcutEnum.last_365_days,
  DateRangeShortcutEnum.last_month,
  DateRangeShortcutEnum.last_6_month,
  DateRangeShortcutEnum.last_12_month,
  DateRangeShortcutEnum.last_year,
];

const getDateRange = (dateRangeOrShortcut: DateRangeShortcutEnum | DateRange) => {
  if (typeof dateRangeOrShortcut !== 'string') {
    return dateRangeOrShortcut;
  }

  const shortcut = dateRangeOrShortcut;
  const startOfToday = DateService.dayjs().startOf('day');
  const endOfToday = startOfToday.endOf('day');

  switch (shortcut) {
    case DateRangeShortcutEnum.today:
      return { start: startOfToday, end: endOfToday };
    case DateRangeShortcutEnum.yesterday:
      return { start: startOfToday.subtract(1, 'day'), end: endOfToday.subtract(1, 'day') };
    case DateRangeShortcutEnum.last_7_days:
      return { start: startOfToday.subtract(7, 'day'), end: endOfToday };
    case DateRangeShortcutEnum.last_30_days:
      return { start: startOfToday.subtract(30, 'day'), end: endOfToday };
    case DateRangeShortcutEnum.last_90_days:
      return { start: startOfToday.subtract(90, 'day'), end: endOfToday };
    case DateRangeShortcutEnum.last_365_days:
      return { start: startOfToday.subtract(365, 'day'), end: endOfToday };
    case DateRangeShortcutEnum.last_month:
      return { start: startOfToday.subtract(1, 'month').startOf('month'), end: endOfToday.subtract(1, 'month').endOf('month') };
    case DateRangeShortcutEnum.last_6_month:
      return { start: startOfToday.subtract(6, 'month').startOf('month'), end: endOfToday.subtract(1, 'month').endOf('month') };
    case DateRangeShortcutEnum.last_12_month:
      return { start: startOfToday.subtract(12, 'month').startOf('month'), end: endOfToday.subtract(1, 'month').endOf('month') };
    case DateRangeShortcutEnum.last_year:
      return { start: startOfToday.subtract(1, 'year').startOf('year'), end: endOfToday.subtract(1, 'year').endOf('year') };
  }
};

export const dateRangeShortcutEnumHelpers: EnumHelpers<DateRangeShortcutEnum> & {
  getDateRange: (dateRangeOrShortcut: DateRangeShortcutEnum | DateRange) => DateRange;
  getValueForDateRange: (dateRange: DateRange) => DateRangeShortcutEnum | undefined;
} = {
  enumValues: enumValues,
  getLabel: (value) => {
    switch(value) {
      case DateRangeShortcutEnum.today:
        return 'Today';
      case DateRangeShortcutEnum.yesterday:
        return 'Yesterday';
      case DateRangeShortcutEnum.last_7_days:
        return 'Last 7 days';
      case DateRangeShortcutEnum.last_30_days:
        return 'Last 30 days';
      case DateRangeShortcutEnum.last_90_days:
        return 'Last 90 days';
      case DateRangeShortcutEnum.last_365_days:
        return 'Last 365 days';
      case DateRangeShortcutEnum.last_month:
        return 'Last month';
      case DateRangeShortcutEnum.last_6_month:
        return 'Last 6 month';
      case DateRangeShortcutEnum.last_12_month:
        return 'Last 12 month';
      case DateRangeShortcutEnum.last_year:
        return 'Last year';
      default:
        return '';
    }
  },
  getColor: () => 'unknown',
  getDateRange: getDateRange,
  getValueForDateRange: (dateRange) => {
    return enumValues.find((key: DateRangeShortcutEnum) => {
      const keyDateRange = getDateRange(key);

      return DateService.dayjs(keyDateRange.start).isSame(dateRange.start, 'day') && DateService.dayjs(keyDateRange.end).isSame(dateRange.end, 'day');
    });
  }
};

export const enum CompareToShortcutEnum {
  previousPeriod = 'previousPeriod',
  lastYear = 'lastYear'
}

const dateRangesAreSame = (dateRange1: DateRange, dateRange2: DateRange) => {
  if(!dateRange1 || !dateRange2) {
    return false;
  }

  return dateRange1.start.isSame(dateRange2.start, 'day') && dateRange1.end.isSame(dateRange2.end, 'day');
};

export const compareToEnumHelpers: EnumHelpers<CompareToShortcutEnum> & {
  getDateRange: (dateRangeOrShortcut: DateRange | DateRangeShortcutEnum, compareToDateRange: CompareToShortcutEnum | DateRange | null) => DateRange | null;
  getValueForDateRange: (dateRange: DateRange, compareToDateRange: DateRange | null) => CompareToShortcutEnum | undefined;
} = {
  enumValues: [
    CompareToShortcutEnum.previousPeriod,
    CompareToShortcutEnum.lastYear
  ],
  getLabel: (value) => {
    switch (value) {
      case CompareToShortcutEnum.lastYear:
        return 'Previous Year';
      case CompareToShortcutEnum.previousPeriod:
        return 'Previous Period';
      default:
        return 'No Comparison';
    }
  },
  getDateRange: (dateRangeOrShortcut, compareToDateRange) => {
    if (typeof compareToDateRange !== 'string') {
      return compareToDateRange;
    }

    const dateRange = dateRangeShortcutEnumHelpers.getDateRange(dateRangeOrShortcut);

    switch (compareToDateRange) {
      case CompareToShortcutEnum.lastYear:
        return {
          start: dateRange.start.subtract(1, 'year'),
          end: dateRange.end.subtract(1, 'year'),
        };
      case CompareToShortcutEnum.previousPeriod:
        const periodDuration = dateRange.end.diff(dateRange.start, 'days') + 1;

        return { start: dateRange.start.subtract(periodDuration, 'days'), end: dateRange.end.subtract(periodDuration, 'days') };
      default:
        return null;
    }
  },
  getValueForDateRange: (inputDateRange, dateRange) => {
    if(!dateRange) {
      return undefined;
    }
    const lastYearDateRange = {
      start: inputDateRange.start.subtract(1, 'year'),
      end: inputDateRange.end.subtract(1, 'year'),
    };

    if(dateRangesAreSame(dateRange, lastYearDateRange)) {
      return CompareToShortcutEnum.lastYear;
    }

    const periodDuration = inputDateRange.end.diff(inputDateRange.start, 'days') + 1;
    const prevPeriodDateRange = { start: inputDateRange.start.subtract(periodDuration, 'days'), end: inputDateRange.end.subtract(periodDuration, 'days') };

    if(dateRangesAreSame(dateRange, prevPeriodDateRange)) {
      return CompareToShortcutEnum.previousPeriod;
    }

    return undefined;
  },
  getColor: () => 'unknown'
};