import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export type ResourcePaginateProps<T> = {
  resourceList: T[];
  currentResource: T;
  basePath: string;
};

export const ResourcePaginate = <T extends { _id: string }>({ resourceList, currentResource, basePath }: ResourcePaginateProps<T>) => {
  const navigate = useNavigate();

  const currentId = currentResource._id;
  const currentIndex = resourceList.findIndex(resource => resource._id === currentId);

  const onPrevious = () => {
    const previousResourceId = resourceList[currentIndex - 1]._id;

    navigate(`${basePath}/${previousResourceId}`);
  };

  const onNext = () => {
    const nextResourceId = resourceList[currentIndex + 1]._id;

    navigate(`${basePath}/${nextResourceId}`);
  };

  const disableBack = currentIndex <= 0;
  const disableNext = currentIndex >= resourceList.length - 1;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
      <Button variant="outlined" color="inherit" size="small" disabled={disableBack} onClick={onPrevious}>{'<'} Previous</Button>
      <Button variant="outlined" color="inherit" size="small" disabled={disableNext} onClick={onNext}>Next {'>'}</Button>
    </Box>
  );
};