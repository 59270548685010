import { Box, Skeleton, Typography } from '@mui/material';
import { ProductionTimelineAnalyticsSeriesType, SaleTimelineAnalyticsSeriesType } from 'api/actions';
import { DateRange } from 'components';
import React from 'react';
import { currencyFormatterWithoutDecimals, DateService } from 'services';

export type ChartDateRangeChipProps = {
  dateRange: DateRange;
  indicatorColor?: string;
  children?: React.ReactNode;
};

export const formatChartDateRange = (dateRange: DateRange) => {
  if(!dateRange) {
    return 'Unknown';
  }
  if(dateRange.start.year() === dateRange.end.year()) {
    if(dateRange.start.isSame(dateRange.end, 'day')) {
      return `${dateRange.end.format('MMM D')}, ${dateRange.start.year()}`;
    }
    return `${dateRange.start.format('MMM D')}—${dateRange.end.format('MMM D')}, ${dateRange.start.year()}`;
  }
  return DateService.getFormattedDateRange(dateRange.start, dateRange.end, false);
};

export const ChartDateRangeChip: React.FC<ChartDateRangeChipProps> = props => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        px: 1,
        py: 0.5,
        gap: 1,
        borderWidth: 1,
        borderRadius: 2,
        borderborderColor: theme.palette.background.defaultDarker,
        backgroundColor: theme.palette.background.default,
        minWidth: 'fit-content',
        height: 28
      })}
    >
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: 2,
          backgroundColor: props.indicatorColor,
        }}
      />
      <Typography variant="body2" color="text.secondary" >{formatChartDateRange(props.dateRange)}</Typography>
      {props.children}
    </Box>
  );
};


export type SalesTimelineLegendChipProps = {
  series: SaleTimelineAnalyticsSeriesType[];
  colors: string[];
  isLoading?: boolean;
  estSeriesLength?: number;
};

export const SalesTimelineLegendChips: React.FC<SalesTimelineLegendChipProps> = ({ series, colors, isLoading, estSeriesLength }) => {

  if (isLoading) {
    return (
      <Box p={2} pt={0} display="flex" gap={1} alignItems="center" flexWrap="wrap">
        {Array.from({ length: estSeriesLength ?? 1 }).map((_, idx) => <Skeleton key={idx} variant="rectangular" width={200} height={28} sx={{ borderRadius: 2 }} />)}
      </Box>
    );
  }

  return (
    <Box p={2} pt={0} display="flex" gap={1} alignItems="center" flexWrap="wrap">
      {series.map(({ start, end, id, data }, idx) => {
        const indicatorColor = colors[idx % colors.length];

        return (
          <ChartDateRangeChip
            key={id}
            dateRange={{ start: DateService.dayjs(start), end: DateService.dayjs(end) }}
            indicatorColor={indicatorColor}
          >
            <Box ml={0.5} display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>{data.reduce((acc, d) => acc + d.count, 0)} sales</Typography>
              <Typography variant="body2" color="text.secondary">{currencyFormatterWithoutDecimals.format(data.reduce((acc, d) => acc + d.revenue, 0))}</Typography>
            </Box>
          </ChartDateRangeChip>
        );
      })}
    </Box>
  );
};
export type ProductionTimelineLegendChipsProps = {
  series: ProductionTimelineAnalyticsSeriesType[];
  colors: string[];
  isLoading?: boolean;
  estSeriesLength?: number;
};

export const ProductionTimelineLegendChips: React.FC<ProductionTimelineLegendChipsProps> = ({ series, colors, isLoading, estSeriesLength }) => {

  if (isLoading) {
    return (
      <Box p={2} pt={0} display="flex" gap={1} alignItems="center" flexWrap="wrap">
        {Array.from({ length: estSeriesLength ?? 1 }).map((_, idx) => <Skeleton key={idx} variant="rectangular" width={200} height={28} sx={{ borderRadius: 2 }} />)}
      </Box>
    );
  }

  return (
    <Box p={2} pt={0} display="flex" gap={1} alignItems="center" flexWrap="wrap">
      {series.map(({ start, end, id, data }, idx) => {
        const indicatorColor = colors[idx % colors.length];

        return (
          <ChartDateRangeChip
            key={id}
            dateRange={{ start: DateService.dayjs(start), end: DateService.dayjs(end) }}
            indicatorColor={indicatorColor}
          >
            <Box ml={0.5} display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.secondary" fontWeight={500}>{data.reduce((acc, d) => acc + d.count, 0)} produced</Typography>
            </Box>
          </ChartDateRangeChip>
        );
      })}
    </Box>
  );
};