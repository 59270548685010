import { Box } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { getEvent, GetEventsResponse } from 'api/actions';
import { useEventContext } from 'components/Event/event.context';
import { EventSchedulingTable } from 'components/Table';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useRef } from 'react';

export type EventPreviewDrawerSchedulingProps = {
  schedulingUserId?: string;
};

export const EventPreviewContentScheduling: React.FC<EventPreviewDrawerSchedulingProps> = ({ schedulingUserId }) => {
  const { event } = useEventContext();
  const containerRef = useRef<HTMLElement>(null);
  const scheduligInvalidateQueriesHandler = async (queryClient: QueryClient) => {
    await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));

    const newEvent = await queryClient.fetchQuery(QUERY_KEY.EVENT(event._id), async () => {
      const response = await getEvent(event._id);

      return response.data;
    });

    queryClient.setQueryData<GetEventsResponse['data']>(QUERY_KEY.EVENTS, (events) => {
      return events?.map(_event =>
        _event._id === newEvent._id ? newEvent : _event
      );
    });
  };

  return (
    <Box ref={containerRef} mx={1} py={1} height="calc(100vh - 171px)">
      <EventSchedulingTable event={event} invalidateQueriesHandler={scheduligInvalidateQueriesHandler} userId={schedulingUserId} containerRef={containerRef} />
    </Box>
  );
};
