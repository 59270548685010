import { Box, Card, Skeleton, Typography, useTheme } from '@mui/material';
import { TimePeriodToggle } from './components/TimePeriodToggle';
import { BarOrLineToggle } from './components/BarOrLineToggle';
import { timelineTickFormatter, timelineTooltipFormatter } from './helpers';
import { BarChart, ChartsAxisContentProps, LineChart } from '@mui/x-charts';
import { AnalyticsChartTypeEnum, AnalyticsTimePeriodEnum } from './types';
import { ChartDateRangeChip } from './components/DateRangeChip';
import { DateService } from 'services';
import { AxisTooltip } from './components/AxisTooltip';

type SeriesDataType = {
  label: string;
};

export type SeriesType<TSeriesData extends SeriesDataType = SeriesDataType> = {
  id: string;
  start: string;
  end: string;
  data: TSeriesData[];
};

export type TimelineChartProps<TSeries extends SeriesType<TSeriesData>, TSeriesData extends SeriesDataType = SeriesDataType> = {
  title: string;

  chartType: AnalyticsChartTypeEnum;
  setChartType: (v: AnalyticsChartTypeEnum) => void;
  timePeriod: AnalyticsTimePeriodEnum;
  setTimePeriod: (v: AnalyticsTimePeriodEnum) => void;
  timePeriodOptions: AnalyticsTimePeriodEnum[];
  series: TSeries[];
  getSeriesData: (data: TSeries) => number[];
  isLoading: boolean;
  estSeriesLength: number;

  getLengendChipContent: TimelineLegendChipsProps<TSeries>['getLengendChipContent'];
  getTooltipContent: TimelineAxisTooltipProps<TSeries, TSeriesData>['getTooltipContent'];
};

const getLongest = <T,>(arrays: T[][]): T[] => arrays.reduce((a, b) => a.length > b.length ? a : b, []);

const useColors = () => {
  const theme = useTheme();

  return [ theme.palette.primary.main, theme.palette.secondary.main, theme.palette.success.main ];
};

export const TimelineChart = <TSeries extends SeriesType<TSeriesData>, TSeriesData extends SeriesDataType>(props: TimelineChartProps<TSeries, TSeriesData>) => {
  const colors = useColors();

  const Chart = props.chartType === AnalyticsChartTypeEnum.bar ? BarChart : LineChart;

  return (
    <Card variant="outlined">
      <Box p={2} display="flex" justifyContent="space-between" >
        <Box display="flex" columnGap={3} rowGap={1} flexWrap="wrap">
          <Typography variant="h6">{props.title}</Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="start">
          <TimePeriodToggle timePeriod={props.timePeriod} onTimePeriodChange={props.setTimePeriod} timePeriodOptions={props.timePeriodOptions} />
          <BarOrLineToggle chartType={props.chartType} onChartTypeChange={props.setChartType} />
        </Box>
      </Box>
      <TimelineLegendChips
        series={props.series}
        getLengendChipContent={props.getLengendChipContent}
        estSeriesLength={props.estSeriesLength}
        isLoading={props.isLoading}
      />
      <Chart
        margin={{ top: 24, right: 24, bottom: 40 }}
        slotProps={{ legend: { hidden: true } }}
        grid={{ horizontal: true }}
        colors={colors}
        xAxis={[
          {
            scaleType: 'band',
            data: getLongest(props.series.map(d => d.data))?.map(d => d.label) ?? [],
            valueFormatter: (value: string) => timelineTickFormatter({ value, timePeriod: props.timePeriod }),
            tickLabelStyle: {
              fontSize: 11,
              lineHeight: 1.1
            }
          },
        ]}
        borderRadius={8}
        tooltip={{ trigger: 'axis' }}
        slots={{
          axisContent: axisProps => (
            <TimelineAxisTooltip
              {...axisProps}
              timePeriod={props.timePeriod}
              getSeries={id => props.series.find(s => s.id === id)}
              getTooltipContent={props.getTooltipContent}
            />
          )
        }}
        series={props.series.map(s => ({
          id: s.id,
          data: props.getSeriesData(s),
          showMark: false,
        }))}
        height={400}
      />

    </Card>
  );
};

export type TimelineLegendChipsProps<TSeries = SeriesType> = {
  series: TSeries[];
  getLengendChipContent: (series: TSeries) => React.ReactNode;
  estSeriesLength: number;
  isLoading: boolean;
};

export const TimelineLegendChips = <TSeries extends SeriesType<TSeriesData>, TSeriesData extends SeriesDataType>(props: TimelineLegendChipsProps<TSeries>) => {
  const colors = useColors();

  if (props.isLoading) {
    return (
      <Box p={2} pt={0} display="flex" gap={1} alignItems="center" flexWrap="wrap">
        {Array.from({ length: props.estSeriesLength ?? 1 }).map((_, idx) => <Skeleton key={idx} variant="rectangular" width={200} height={28} sx={{ borderRadius: 2 }} />)}
      </Box>
    );
  }

  return (
    <Box p={2} pt={0} display="flex" gap={1} alignItems="center" flexWrap="wrap">
      {props.series.map((series, idx) => {
        const { start, end, id } = series;
        const indicatorColor = colors[idx % colors.length];

        return (
          <ChartDateRangeChip
            key={id}
            dateRange={{ start: DateService.dayjs(start), end: DateService.dayjs(end) }}
            indicatorColor={indicatorColor}
          >
            {props.getLengendChipContent(series)}
          </ChartDateRangeChip>
        );
      })}
    </Box>
  );
};

export type TimelineAxisTooltipProps<TSeries extends SeriesType<TSeriesData>, TSeriesData extends SeriesDataType> = {
  getSeries: (id: string) => TSeries | undefined;
  timePeriod: AnalyticsTimePeriodEnum;
  getTooltipContent: (seriesData: TSeries['data'][number]) => {
    className?: string;
    content: React.ReactNode;
  }[];
} & ChartsAxisContentProps;

export const TimelineAxisTooltip = <TSeries extends SeriesType<TSeriesData>, TSeriesData extends SeriesDataType>(props: TimelineAxisTooltipProps<TSeries, TSeriesData>) => {
  const dataIndex = props.dataIndex;

  if (dataIndex === undefined || dataIndex === null) {
    return null;
  }

  return (
    <AxisTooltip>
      <table>
        <tbody>
          {props.series.map((chartSeries) => {
            const series = props.getSeries(String(chartSeries.id));

            if (!series) {
              return null;
            }

            const seriesData = series.data[dataIndex];

            if (!seriesData) {
              return null;
            }

            return (
              <tr key={series.id}>
                <td>
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 2,
                      backgroundColor: chartSeries.color,
                    }}
                  />
                </td>
                <td>
                  <Typography fontSize="small">
                    {timelineTooltipFormatter({
                      value: seriesData.label,
                      timePeriod: props.timePeriod,
                    })}
                  </Typography>
                </td>
                {props.getTooltipContent(seriesData).map(({ className, content }, idx) => (
                  <td key={idx} className={className}>
                    {content}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </AxisTooltip>
  );
};
