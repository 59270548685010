import { Formik } from 'formik';
import { useMemo } from 'react';
import { DateRange, DateRangeShortcutEnum, dateRangeShortcutEnumHelpers } from './helpers';
import { ArrowRightAlt, CalendarTodayOutlined, CheckOutlined } from '@mui/icons-material';
import { PopoverButton } from 'components/Button';
import { DateService } from 'services';
import { Box, Button, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';

export type DateRangePickerPopoverProps = {
  dateRange: DateRange | DateRangeShortcutEnum;
  onChange: (value: DateRange | DateRangeShortcutEnum) => void;
};

export type DateRangeFormInput = { dateRange: DateRange | DateRangeShortcutEnum };

export const DateRangePickerPopover: React.FC<DateRangePickerPopoverProps> = props => {
  const label = useMemo(() => {
    if (typeof props.dateRange === 'string') {
      return dateRangeShortcutEnumHelpers.getLabel(props.dateRange);
    }

    return DateService.getFormattedDateRange(props.dateRange.start, props.dateRange.end);
  }, [ props.dateRange ]);

  return (
    <PopoverButton label={label} id="daterange-popover" startIcon={<CalendarTodayOutlined />}>
      {({ handleClose }) => {
        return (
          <Formik<DateRangeFormInput>
            initialValues={{ dateRange: props.dateRange }}
            onSubmit={(values) => props.onChange(values.dateRange)}
          >
            {formik => {
              const { start, end } = dateRangeShortcutEnumHelpers.getDateRange(formik.values.dateRange);

              return (
                <div>
                  <Box>
                    <Box display="flex" height="100%">
                      <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        width="200px"
                        borderRight={theme => `1px solid ${theme.palette.divider}`}
                        boxShadow={theme => `inset 0 -16px 16px -16px ${theme.palette.divider}`}
                      >
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          bottom={0}
                          width="100%"
                          display="flex"
                          flexDirection="column"
                        >
                          <Box flex={1} overflow="auto" display="flex" flexDirection="column" gap={1}>
                            <List>
                              {dateRangeShortcutEnumHelpers.enumValues.map(shortcut => {
                                const isSelected = typeof formik.values.dateRange === 'string' && formik.values.dateRange === shortcut;

                                return (
                                  <ListItemButton
                                    selected={isSelected}
                                    key={shortcut}
                                    onClick={() => formik.setValues({ dateRange: shortcut })}
                                    sx={{ position: 'relative' }}
                                  >
                                    <ListItemText
                                      primary={dateRangeShortcutEnumHelpers.getLabel(shortcut)}
                                      primaryTypographyProps={{ fontWeight: isSelected ? 500 : 400, fontSize: 'small' }}
                                    />
                                    {isSelected && (
                                      <Box position="absolute" right={0} pr={2} height="100%" display="flex" alignItems="center">
                                        <CheckOutlined fontSize="small" color="primary" />
                                      </Box>
                                    )}
                                  </ListItemButton>
                                );
                              })}
                            </List>
                          </Box>
                        </Box>
                      </Box>

                      <Box>
                        <Box py={1.5} pt={2} display="flex" alignItems="center">
                          <Box flex={1} px={2} textAlign="center">
                            <Typography fontWeight={500} fontSize="large">{start.format('MMMM Do, YYYY') ?? '___'}</Typography>
                          </Box>
                          <Box display="flex" color="text.secondary">
                            <ArrowRightAlt fontSize="large" color="inherit" />
                          </Box>
                          <Box flex={1} px={2} textAlign="center">
                            <Typography fontWeight={500} fontSize="large">{end.format('MMMM Do, YYYY') ?? '___'}</Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <StaticDateRangePicker
                          value={[ start, end ]}
                          onChange={(value) => value[0] && value[1] && formik.setValues({ dateRange: { start: value[0], end: value[1] } })}
                          slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
                          calendars={2}
                        />
                      </Box>
                    </Box>

                  </Box>

                  <Divider />
                  <Box display="flex" justifyContent="flex-end" py={2} gap={2} px={2}>
                    <Button onClick={handleClose} size="small" color="inherit">Cancel</Button>
                    <Button
                      onClick={() => {
                        formik.handleSubmit();
                        handleClose();
                      }}
                      disabled={!formik.isValid} size="small" variant="contained"
                    >
                      Apply
                    </Button>
                  </Box>
                </div>
              );
            }}
          </Formik>
        );
      }}
    </PopoverButton>
  );
};


