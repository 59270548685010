import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSalesTimelineAnalytics } from 'queries';
import { currencyFormatterWithoutDecimals, DateService } from 'services';
import { GetEventResponse, GetEventSalesAnalyticsInput } from 'api/actions';
import { AnalyticsChartTypeEnum, AnalyticsTimePeriodEnum } from './types';
import { TimelineChart } from './TimelineChart';


export type EventSalesTimelineChartProps = {
  event: GetEventResponse['data'];
};

export const EventSalesTimelineChart: React.FC<EventSalesTimelineChartProps> = ({ event }) => {
  const [ chartType, setChartType ] = useState<AnalyticsChartTypeEnum>(AnalyticsChartTypeEnum.bar);
  const [ timePeriod, setTimePeriod ] = useState<AnalyticsTimePeriodEnum>(AnalyticsTimePeriodEnum.day);
  const input: GetEventSalesAnalyticsInput = {
    timePeriod: timePeriod,
    timezone: DateService.dayjs.tz.guess(),
  };

  const seriesInput = event.eventCollection.events.map(({ _id }) => ({ id: _id, eventId: _id }));
  const { data: eventSeries = [], isLoading } = useSalesTimelineAnalytics({
    ...input,
    series: seriesInput,
  });

  return (
    <TimelineChart
      title="Sales"
      chartType={chartType}
      setChartType={setChartType}
      timePeriod={timePeriod}
      setTimePeriod={setTimePeriod}
      timePeriodOptions={[ AnalyticsTimePeriodEnum.day, AnalyticsTimePeriodEnum.week, AnalyticsTimePeriodEnum.month, AnalyticsTimePeriodEnum.year ]}
      series={eventSeries}
      getSeriesData={series => series.data.map(d => d.count)}
      isLoading={isLoading}
      estSeriesLength={seriesInput.length}
      getLengendChipContent={s => (
        <Box ml={0.5} display="flex" alignItems="center" gap={1}>
          <Typography variant="body2" color="text.secondary" fontWeight={500}>{s.data.reduce((acc, d) => acc + d.count, 0)} sales</Typography>
          <Typography variant="body2" color="text.secondary">{currencyFormatterWithoutDecimals.format(s.data.reduce((acc, d) => acc + d.revenue, 0))}</Typography>
        </Box>
      )}
      getTooltipContent={data => [
        {
          content: <Typography variant="body2" fontWeight={500}>{data.count} sales</Typography>,
        },
        {
          content: <Typography variant="body2">{currencyFormatterWithoutDecimals.format(data.revenue)}</Typography>,
        }
      ]}
    />
  );
};