import { authAxiosRequest } from 'api/axios';
import { Event, EventDate, Order, Product, Sale, Storefront, Team, User } from 'api/resources';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

export type GetEventsSalesResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name'>;
    order: ResourceWithPopulated<PickFromResource<Order, 'name' | 'type' | 'payment'>, {
      event: ResourceWithPopulated<PickFromResource<Event, 'team' | 'name' | 'timezone'>, {
        team: PickFromResource<Team, 'name' | 'color'>;
      }>;
      eventDate: PickFromResource<EventDate, 'dateAsUtc'>;
      storefront: never;
      user?: PickFromResource<User, 'name'>;
    }>;
    saleDate: string;
  }>[];
};

export type GetSalesInput = {
  startDate?: string;
  endDate?: string;
};

export const getEventsSales = (params?: GetSalesInput) => {
  return authAxiosRequest<GetEventsSalesResponse>('/api/sales', { params: { ...params, kind: 'events' } });
};

export type GetStorefrontsSalesResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name'>;
    order: ResourceWithPopulated<PickFromResource<Order, 'name' | 'type' | 'payment'>, {
      event: never;
      eventDate: never;
      storefront: Storefront;
      user?: PickFromResource<User, 'name'>;
    }>;
    saleDate: string;
  }>[];
};

export const getStorefrontsSales = (params?: GetSalesInput) => {
  return authAxiosRequest<GetStorefrontsSalesResponse>('/api/sales', { params: { ...params, kind: 'storefronts' } });
};

export type GetSalesResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name'>;
    order: ResourceWithPopulated<PickFromResource<Order, 'name' | 'type' | 'payment'>, {
      eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
      event?: ResourceWithPopulated<PickFromResource<Event, 'team' | 'name' | 'timezone'>, {
        team: PickFromResource<Team, 'name' | 'color'>;
      }>;
      storefront?: Storefront;
      user?: PickFromResource<User, 'name'>;
    }>;
    saleDate: string;
  }>[];
};

export const getSales = (params?: GetSalesInput) => {
  return authAxiosRequest<GetSalesResponse>('/api/sales', { params: params });
};

export type GetSalesForEventResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name'>;
    order: ResourceWithPopulated<PickFromResource<Order, 'name' | 'type' | 'payment'>, {
      event: PickFromResource<Event, 'timezone'>;
      eventDate: PickFromResource<EventDate, 'dateAsUtc'>;
      user?: PickFromResource<User, 'name'>;
    }>;
    saleDate: string;
  }>[];
};

export const getSalesForEvent = (eventId: string) => {
  return authAxiosRequest<GetSalesForEventResponse>(`/api/events/${eventId}/sales`);
};

export type GetSaleResponse = {
  data: ResourceWithPopulated<Sale, {
    product: PickFromResource<Product, 'name' | 'imageUrl'>;
    order: ResourceWithPopulated<PickFromResource<Order, 'name' | 'type' | 'payment'>, {
      event?: ResourceWithPopulated<PickFromResource<Event, 'team' | 'name' | 'timezone'>, {
        team: PickFromResource<Team, 'name'>;
      }>;
      eventDate?: PickFromResource<EventDate, 'dateAsUtc'>;
      storefront?: Storefront;
      user?: PickFromResource<User, 'name'>;
    }>;
    saleDate: string;
  }>;
};

export const getSale = (id: string) => {
  return authAxiosRequest<GetSaleResponse>(`/api/sales/${id}`);
};

export type UpdateSaleInput = Partial<Pick<Sale, 'frame' | 'salePrice' | 'product'>>;

export const updateSale = (id: string, data: UpdateSaleInput) => {
  return authAxiosRequest<GetSaleResponse>(`/api/sales/${id}`, { method: 'PUT', data });
};

export const deleteSale = (id: string) => {
  return authAxiosRequest(`/api/sales/${id}`, { method: 'DELETE' });
};