
import { DateService } from 'services';
import { AnalyticsTimePeriodEnum, ChartDateRangeType } from './types';
import { EnumHelpers } from 'helpers/enums/types';


export const timelineTickFormatter = ({ value, timePeriod }: {value: string; timePeriod: AnalyticsTimePeriodEnum}) => {
  if (timePeriod === AnalyticsTimePeriodEnum.hour) {
    return DateService.dayjs(value).format('MMM D, h:mm\nA');
  }

  if (timePeriod === AnalyticsTimePeriodEnum.day) {
    return DateService.dayjs(value).format('MMM D');
  }

  if (timePeriod === AnalyticsTimePeriodEnum.week) {
    return `${DateService.dayjs(value).startOf('week').format('MMM D')} –\n${DateService.dayjs(value).endOf('week').format('MMM D')}`;
  }

  if (timePeriod === AnalyticsTimePeriodEnum.month) {
    return DateService.dayjs(value).format('MMM');
  }

  if (timePeriod === AnalyticsTimePeriodEnum.year) {
    return DateService.dayjs(value).format('YYYY');
  }

  return DateService.dayjs(value).format('MMM D');
};

export const timelineTooltipFormatter = ({ value, timePeriod }: {value: string; timePeriod: AnalyticsTimePeriodEnum}) => {
  if (timePeriod === AnalyticsTimePeriodEnum.hour) {
    return DateService.dayjs(value).format('MMM D, h:mm A');
  }

  if (timePeriod === AnalyticsTimePeriodEnum.day) {
    return DateService.dayjs(value).format('MMM D, YYYY');
  }

  if (timePeriod === AnalyticsTimePeriodEnum.week) {
    return `${DateService.dayjs(value).startOf('week').format('MMM D')} – ${DateService.dayjs(value).endOf('week').format('MMM D YYYY')}`;
  }

  if (timePeriod === AnalyticsTimePeriodEnum.month) {
    return DateService.dayjs(value).format('MMM, YYYY');
  }

  if (timePeriod === AnalyticsTimePeriodEnum.year) {
    return DateService.dayjs(value).format('YYYY');
  }

  return DateService.dayjs(value).format('MMM D');
};

const enumValues: AnalyticsTimePeriodEnum[] = [
  AnalyticsTimePeriodEnum.hour,
  AnalyticsTimePeriodEnum.day,
  AnalyticsTimePeriodEnum.week,
  AnalyticsTimePeriodEnum.month,
  AnalyticsTimePeriodEnum.year
];

const isTimePeriodValidForDateRange = (timePeriod: AnalyticsTimePeriodEnum, dateRange: ChartDateRangeType) => {
  const daysInRange = dateRange.end.diff(dateRange.start, 'day');

  if(daysInRange === undefined) {
    return false;
  }

  switch (timePeriod) {
    case AnalyticsTimePeriodEnum.hour:
      return daysInRange <= 2;
    case AnalyticsTimePeriodEnum.day:
      return daysInRange <= 90;
    case AnalyticsTimePeriodEnum.week:
      return daysInRange >= 8 && daysInRange <= 500;
    case AnalyticsTimePeriodEnum.month:
      return daysInRange >= 31;
    default:
      return daysInRange >= 366;
  }
};

export const analyticsTimePeriodEnumHelpers: Omit<EnumHelpers<AnalyticsTimePeriodEnum>, 'getColor'> & {
  isTimePeriodValidForDateRange: (timePeriod: AnalyticsTimePeriodEnum, dateRange: ChartDateRangeType) => boolean;
  getValidTimePeriodsForDateRange: (dateRange: ChartDateRangeType) => AnalyticsTimePeriodEnum[];
} = {
  enumValues: enumValues,
  getLabel: (timePeriod) => {
    switch (timePeriod) {
      case AnalyticsTimePeriodEnum.hour:
        return 'Hour';
      case AnalyticsTimePeriodEnum.day:
        return 'Day';
      case AnalyticsTimePeriodEnum.week:
        return 'Week';
      case AnalyticsTimePeriodEnum.month:
        return 'Month';
      default:
        return 'Year';
    }
  },
  isTimePeriodValidForDateRange: isTimePeriodValidForDateRange,
  getValidTimePeriodsForDateRange: (dateRange: ChartDateRangeType) => {
    return enumValues.filter(p => isTimePeriodValidForDateRange(p, dateRange));
  }
};