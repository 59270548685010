import { Box, Card, Typography } from '@mui/material';
import React from 'react';

type ReportCardProps = {
  label: string;
  primary: string | number;
  secondary: string;
  height?: number;
};

export const ReportCard: React.FC<ReportCardProps> = ({ label, primary, secondary, height }) => {

  return (
    <Box flex={1} display="flex" maxHeight={height}>
      <Card
        variant="outlined"
        sx={{
          flex: 1,
          p: 2,
          py: 1.5,
          gap: 2,
          display: 'flex',
          alignItems: 'center',
          borderRadius: '8px'
        }}
      >
        <Box bgcolor="primary.background" width="60px" height={height ? height - 20 : '60px'} borderRadius="8px" display="flex" alignItems="center" justifyContent="center">
          <Typography fontWeight={500}>{primary}</Typography>
        </Box>
        <Box>
          <Typography color="text.secondary">{label}</Typography>
          <Typography fontSize="large">{secondary}</Typography>
        </Box>
      </Card>
    </Box>
  );
};
