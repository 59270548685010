import React from 'react';
import { useProductOutletContext } from '../Product.base';
import { Box, Grid, Typography } from '@mui/material';
import { analyticsTimePeriodEnumHelpers, CardContentContainer, ProductionTimelineChart, useStockUpdateLogTable } from 'components';
import { ProductInventoryTable } from '../components/ProductInventoryTable.component';
import { ProductPageSection } from '../types';
import { DateService } from 'services';

export const InventorySection = () => {
  const { product } = useProductOutletContext();
  const { StockUpdateLogTable, SelectionChipGroup } = useStockUpdateLogTable({ productId: product._id, initialLimit: 'all' });

  return (
    <Box component="div" id={ProductPageSection.INVENTORY}>
      <Typography variant="h6" fontWeight={400} mb={1}>Inventory</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={7}>
          <ProductionTimelineChart
            productId={product._id}
            timePeriodOptions={
              analyticsTimePeriodEnumHelpers.getValidTimePeriodsForDateRange({
                start: DateService.dayjs(product.createdAt),
                end: DateService.dayjs().endOf('day')
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <CardContentContainer sx={{ height: '100%' }} cardContentSx={{ mx: -2 }}>
            <ProductInventoryTable />
          </CardContentContainer>
        </Grid>
        <Grid item xs={12}>
          <CardContentContainer
            cardContentSx={{ pb: theme => `${theme.spacing(2)} !important` }}
            action={<SelectionChipGroup />}
          >
            <Box height="500px" mx={-2} mt={-2}>
              <StockUpdateLogTable sx={{ border: 'none' }} />
            </Box>
          </CardContentContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
