import { NoSsr, Paper, Popper } from '@mui/material';
import { useMouseTracker } from '@mui/x-charts';

export type AxisTooltipProps = {
  children: React.ReactNode;
};

export const AxisTooltip: React.FC<AxisTooltipProps> = (props) => {
  const mousePosition = useMouseTracker(); // Track the mouse position on chart.

  if (!mousePosition) {
    // No data to display
    return null;
  }

  // The pointer type can be used to have different behavior based on pointer type.
  const isMousePointer = mousePosition?.pointerType === 'mouse';
  // Adapt the tooltip offset to the size of the pointer.
  const yOffset = isMousePointer ? 0 : 40 - mousePosition.height;


  return (
    <NoSsr>
      <Popper
        sx={{
          pointerEvents: 'none',
          zIndex: (theme) => theme.zIndex.modal,
        }}
        open
        placement={isMousePointer ? 'top-end' : 'top'}
        anchorEl={generateVirtualElement(mousePosition)}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [ 0, yOffset ],
            },
          },
        ]}
      >
        <Paper
          elevation={2}
          sx={{
            m: 1,
            border: 'solid',
            borderWidth: 1,
            borderColor: 'divider',
            table: { borderSpacing: 0 },
            thead: {
              td: {
                px: 1.5,
                py: 0.75,
                borderBottom: 'solid',
                borderWidth: 2,
                borderColor: 'divider',
              },
            },
            tbody: {
              'tr:first-child': { td: { paddingTop: 1.5 } },
              'tr:last-child': { td: { paddingBottom: 1.5 } },
              tr: {
                'td:first-child': { paddingLeft: 1.5 },
                'td:last-child': { paddingRight: 1.5 },
                td: {
                  paddingRight: '7px',
                  paddingBottom: '10px',
                },
              },
            },
          }}
        >
          {props.children}
        </Paper>
      </Popper>
    </NoSsr>
  );
};

export type MousePosition = {
  x: number;
  y: number;
  pointerType: 'mouse' | 'touch' | 'pen';
  height: number;
};

/**
   * Helper faking an element bounding box for the Popper.
   */
export function generateVirtualElement(mousePosition: { x: number; y: number } | null) {
  if (mousePosition === null) {
    return {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        toJSON: () => '',
      }),
    };
  }
  const { x, y } = mousePosition;
  const boundingBox = {
    width: 0,
    height: 0,
    x,
    y,
    top: y,
    right: x,
    bottom: y,
    left: x,
  };

  return {
    getBoundingClientRect: () => ({
      ...boundingBox,
      toJSON: () => JSON.stringify(boundingBox),
    }),
  };
}