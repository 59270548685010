import { Formik } from 'formik';
import React from 'react';
import { ResourceForm } from 'types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetEventFeedbackResponse, updateEventFeedback } from 'api/actions/eventFeedback';
import { QUERY_KEY } from 'queries/query-keys';
import { processFormValueUpdate, removeUnchanged } from 'helpers';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';
import { useEventContext } from 'components/Event/event.context';
import { UpdateResourceModal } from 'components/Modal';
import { EventFeedbackForm } from 'components/Event/forms/EventFeedback.form';
import { eventFeedbackValidationSchema } from 'components/Event/forms';

export type EditEventFeedbackModalProps = {
  eventFeedback: GetEventFeedbackResponse['data'][number];
  onClose: () => void;
};

export const EditEventFeedbackModal: React.FC<EditEventFeedbackModalProps> = ({ onClose, eventFeedback }) => {
  const { event } = useEventContext();
  const queryClient = useQueryClient();
  const initialEditEventFeedbackState: ResourceForm['eventFeedback'] = {
    overall: eventFeedback.overall,
    busyness: eventFeedback.busyness,
    sales: eventFeedback.sales,
    food: eventFeedback.food,
    artists: eventFeedback.artists,
    parking: eventFeedback.parking,

    unloadPullUp: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventFeedback.unloadPullUp),
    packUpPullUp: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventFeedback.packUpPullUp),
    doAgain: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventFeedback.doAgain),
    needMoreStaff: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventFeedback.needMoreStaff),
    weatherAffectedSales: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventFeedback.weatherAffectedSales),

    sameBoothLocation: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventFeedback.sameBoothLocation),
    boothNotes: eventFeedback.boothNotes ?? '',
    nextYearBoothLocation: eventFeedback.nextYearBoothLocation ?? '',
    nextYearLargerBooth: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventFeedback.nextYearLargerBooth),
    nextYearMultipleLocations: yesNoEnumHelpers.yesNo.getEnumValueOrNull(eventFeedback.nextYearMultipleLocations),

    complaints: eventFeedback.complaints ?? '',
    nextYearRecommendations: eventFeedback.nextYearRecommendations ?? '',
    note: eventFeedback.note ?? '',
  };
  const updateEventFeedbackMutation = useMutation({
    mutationFn: (form: ResourceForm['eventFeedback']) => {
      const updates = removeUnchanged(form, initialEditEventFeedbackState);

      return updateEventFeedback(event._id, eventFeedback._id, {
        ...updates,

        unloadPullUp: processFormValueUpdate.yesNo(updates.unloadPullUp),
        packUpPullUp:  processFormValueUpdate.yesNo(updates.packUpPullUp),
        doAgain:  processFormValueUpdate.yesNo(updates.doAgain),
        needMoreStaff:  processFormValueUpdate.yesNo(updates.needMoreStaff),
        weatherAffectedSales:  processFormValueUpdate.yesNo(updates.weatherAffectedSales),

        sameBoothLocation:  processFormValueUpdate.yesNo(updates.sameBoothLocation),
        boothNotes: processFormValueUpdate.string(form.boothNotes),
        nextYearBoothLocation: processFormValueUpdate.string(form.nextYearBoothLocation),
        nextYearLargerBooth:  processFormValueUpdate.yesNo(updates.nextYearLargerBooth),
        nextYearMultipleLocations:  processFormValueUpdate.yesNo(updates.nextYearMultipleLocations),

        complaints: processFormValueUpdate.string(form.complaints),
        note: processFormValueUpdate.string(form.note),
        nextYearRecommendations: processFormValueUpdate.string(form.nextYearRecommendations)
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT_FEEDBACK(event._id) });
      onClose();
    }
  });
  const onSubmit = async (form: ResourceForm['eventFeedback']) => {
    updateEventFeedbackMutation.mutate(form);

    onClose();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialEditEventFeedbackState}
      validationSchema={eventFeedbackValidationSchema}
    >
      {
        (formik) => {
          return (
            <UpdateResourceModal
              open
              autoHeight
              onClose={onClose}
              onSave={formik.handleSubmit}
              title="Edit Event Feedback"
              saveDisabled={!formik.isValid}
              loading={formik.isSubmitting}
            >
              <EventFeedbackForm />
            </UpdateResourceModal>
          );
        }
      }
    </Formik>
  );
};
