import { OpenInNewOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import { GetEventDatesResponse, GetUsersAvailabilitySetHashResponse } from 'api/actions';
import { User, EventDateStaffStatusEnum } from 'api/resources';
import { CardContentContainer, EditableImage, CardContentGrid, CardContentLink, AvailabilitySetAlert } from 'components';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';
import { DateService } from 'services';

export type EventManagementLogisticsStaffScheduleUserCardProps = {
  user: User;
  eventDates: GetEventDatesResponse['data'];
  eventDatesLoading: boolean;
  usersAvailabilitySetHash: GetUsersAvailabilitySetHashResponse['data'];
  usersAvailabilitySetHashLoading?: boolean;
};

export const EventManagementLogisticsStaffScheduleUserCard: React.FC<EventManagementLogisticsStaffScheduleUserCardProps> = ({ user, eventDates, eventDatesLoading, usersAvailabilitySetHash, usersAvailabilitySetHashLoading }) => {
  const navigate = useNavigate();
  const userEventDates = eventDates.filter(eventDate => eventDate.staff.find(staff => staff.user._id === user._id));
  const confirmedDates = userEventDates.filter(eventDate => {
    const status = eventDate.staff.find(staff => staff.user._id === user._id)?.status;

    return status === EventDateStaffStatusEnum.confirmed;
  });
  const requestedDates = userEventDates.filter(eventDate => {
    const status = eventDate.staff.find(staff => staff.user._id === user._id)?.status;

    return status === EventDateStaffStatusEnum.requested;
  });
  let nextEventLabel = '';

  if (userEventDates.length) {
    const diff = DateService.dayjsTz(userEventDates[0].dateAsUtc).diff(DateService.dayjs(), 'days');

    if (diff === 0) {
      nextEventLabel = 'today';
    } else if (diff === 1) {
      nextEventLabel = 'tomorrow';
    } else {
      nextEventLabel = `in ${diff} days`;
    }
  }

  return (
    <CardContentContainer
      disableAutoHeight
      editOptionIcon={<OpenInNewOutlined />}
      showEditOption
      onEdit={() => navigate(DYNAMIC_ROUTES.user.createLink({ userId: user._id }))}
      clickable
    >
      <Box display="flex" alignItems="flex-start" gap={2}>
        <EditableImage
          width="170px"
          height="170px"
          onUpload={()=>{}}
          imageUrl={user.profileImageUrl}
          modalTitle="Update User Image"
          disabledEditable
        />
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%" gap={2} minWidth={0}>
          <Typography variant="h6">{user.name}</Typography>
          <CardContentGrid
            items={[
              {
                title: 'Next upcoming event',
                value: (
                  <Box height={45}>
                    {userEventDates.length ? (
                      <>
                        <CardContentLink href={DYNAMIC_ROUTES.event.createLink({ eventId: userEventDates[0].event._id })} title={userEventDates[0].event.name} />
                        <Typography variant="subtitle2">({nextEventLabel})</Typography>
                      </>
                    ) : '--'}
                  </Box>
                ),
                type: 'full',
                loading: eventDatesLoading
              },
              {
                title: 'Confirmed dates',
                value: confirmedDates.length,
                loading: eventDatesLoading
              },
              {
                title: 'Requested dates',
                value: requestedDates.length,
                loading: eventDatesLoading
              },
            ]}
          />
        </Box>
      </Box>
      <Box mt={1}>
        {usersAvailabilitySetHashLoading ? (
          <Skeleton height={75} width="auto" variant="rectangular" />
        ) : (
          <AvailabilitySetAlert {...usersAvailabilitySetHash[user._id]} />
        )}
      </Box>
    </CardContentContainer>
  );
};