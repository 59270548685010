import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { analyticsTimePeriodEnumHelpers } from '../helpers';
import { AnalyticsTimePeriodEnum } from '../types';

export type TimePeriodToggleProps = {
  timePeriod: AnalyticsTimePeriodEnum;
  onTimePeriodChange: (value: AnalyticsTimePeriodEnum) => void;

  timePeriodOptions?: AnalyticsTimePeriodEnum[];
};

export const TimePeriodToggle: React.FC<TimePeriodToggleProps> = (props) => {
  return (
    <ToggleButtonGroup
      value={props.timePeriod}
      onChange={(_, value) => value && props.onTimePeriodChange(value)}
      exclusive
      size="small"
    >
      {(props.timePeriodOptions ?? analyticsTimePeriodEnumHelpers.enumValues).map((value) => {
        return <ToggleButton key={value} value={value} >{analyticsTimePeriodEnumHelpers.getLabel(value)}</ToggleButton>;
      })}
    </ToggleButtonGroup>
  );
};