import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TeamPageSection } from '../types';
import { useStockUpdateLogTable, CardContentContainer, ProductionTimelineChart, analyticsTimePeriodEnumHelpers } from 'components';
import { useTeamOutletContext } from '../Team.base';
import { DateService } from 'services';

export const InventorySection = () => {
  const { team } = useTeamOutletContext();
  const { StockUpdateLogTable, SelectionChipGroup } = useStockUpdateLogTable({ teamId: team._id });

  return (
    <Stack component="div" id={TeamPageSection.INVENTORY} gap={1}>
      <Typography variant="h6" fontWeight={400}>Inventory</Typography>
      <ProductionTimelineChart
        teamId={team._id}
        timePeriodOptions={
          analyticsTimePeriodEnumHelpers.getValidTimePeriodsForDateRange({
            start: DateService.dayjs(team.createdAt),
            end: DateService.dayjs().endOf('day')
          })
        }
      />
      <CardContentContainer
        cardContentSx={{ pb: theme => `${theme.spacing(2)} !important` }}
        action={<SelectionChipGroup />}
      >
        <Box height="500px" mx={-2} mt={-2}>
          <StockUpdateLogTable sx={{ border: 'none' }} />
        </Box>
      </CardContentContainer>
    </Stack>
  );
};
