import React, { useCallback, useMemo } from 'react';
import { InventoryBatchLayout } from '../components/InventoryBatchLayout.component';
import { Button } from '@mui/material';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { BeforeUnloadPrompt, InventoryTableEditableMode, InventoryTableSettingsModal, InventoryTableStandardFiltersInput, StandardInventoryTableFilters, Table, useAlertSnackbar, useInventoryTable } from 'components';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum, UserEmployeeRoleEnum } from 'api/resources';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { updateInventoryBatch, UpdateInventoryBatchInput, UpdateInventoryBatchStatusEnum } from 'api/actions';
import { QUERY_KEY } from 'queries/query-keys';
import { AxiosError } from 'axios';
import { getInitialTransferConfig, prepareReviewInventoryEdits } from '../helpers';
import { CancelBatchButton } from '../components/CancelBatchButton.component';

export const Review = () => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const navigate = useNavigate();
  const snackbar = useAlertSnackbar();
  const { teams, getProductsSortedAlphabetically } = useProductsPageContext();
  const warehouseTeamId = teams.filter(team => team.isWarehouse)[0]._id;
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const queryClient = useQueryClient();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);
  const initialTransferConfig = useMemo(() => getInitialTransferConfig(inventoryBatch, warehouseTeamId), [ inventoryBatch, warehouseTeamId ]);

  const initialMode = useMemo(() => {
    if ([ InventoryBatchTypeEnum.damaged, InventoryBatchTypeEnum.productionDamaged ].includes(inventoryBatch.type)) {
      return InventoryTableEditableMode.damaged;
    }

    if (inventoryBatch.type === InventoryBatchTypeEnum.production) {
      return InventoryTableEditableMode.unary;
    }

    return InventoryTableEditableMode.transfer;
  }, [ inventoryBatch.type ]);

  const getDisplayStandardFilters = useCallback((): StandardInventoryTableFilters[] => {
    if (!inventoryBatch.team.isWarehouse) {
      return [  'showNotAvailableAtEvents', 'onlyShowEdits' ];
    }

    return [ 'onlyShowEdits' ];
  }, [ inventoryBatch.team.isWarehouse ]);

  const {
    inventoryTableProps,
    editModal,
    hasErrors,
    inventoryEdits,
    hasEdits,
    setSaveLoading,
    onResetEdits,
    settingsModalOpen,
    setSettingsModalOpen,
    standardFilters,
    setStandardFilters,
    transferConfig,
  } = useInventoryTable({
    products,
    teams,
    getStockQuantity: stock => inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged ? stock.productionQuantity : stock.quantity,
    settingsArgs: {
      initialMode,
      initialTransferConfig,
      initialTeamIds: [ inventoryBatch.team._id ],
      initialShowStockMeta: false,
      getDisplayStandardFilters,
    },
    apiArgs: {
      validateTransferEdit: () => undefined,
    },
    teamColumnsArgs: {
      compactTransferColumn: true,
      getTeamColumnHeaderSecondary: () => inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged ? 'production stock' : null,
    },
  });

  const updateMutation = useMutation({
    mutationFn: (input: UpdateInventoryBatchInput) => updateInventoryBatch(inventoryBatch._id, input),
    onSuccess: async (data) => {
      setSaveLoading(false);
      onResetEdits();
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCH(inventoryBatch._id) });

      if (data.data.status === InventoryBatchStatusEnum.closed) {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEY.PRODUCTS });
      }
    },
    onError: (e: AxiosError) => {
      setSaveLoading(false);
      snackbar.error(e.response?.data?.error || 'Failed to update batch');
    }
  });

  const onComplete = async () => {
    setSaveLoading('all');
    updateMutation.mutate({
      updates: prepareReviewInventoryEdits(inventoryBatch, warehouseTeamId, inventoryEdits),
      status: UpdateInventoryBatchStatusEnum.reviewComplete,
    });
  };

  return (
    <InventoryBatchLayout
      actions={(
        <>
          {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && <CancelBatchButton />}
          <Button variant="outlined" onClick={() => navigate(ROUTING_CONFIG.inventoryBatches)} disabled={updateMutation.isLoading}>Cancel Review</Button>
          <Button variant="contained" disabled={hasErrors || updateMutation.isLoading || !Object.keys(inventoryEdits).length} onClick={onComplete}>Complete Review</Button>
        </>
      )}
    >
      {settingsModalOpen && (
        <InventoryTableSettingsModal
          onClose={() => setSettingsModalOpen(false)}
          filterInputs={(
            <InventoryTableStandardFiltersInput
              displayFilters={getDisplayStandardFilters()}
              disableFilters={inventoryBatch.team.isWarehouse ? undefined : [ 'showNotAvailableAtEvents' ]}
              standardFilters={standardFilters}
              setStandardFilters={setStandardFilters}
              teams={teams}
              transferConfig={transferConfig}
            />
          )}
        />
      )}
      <BeforeUnloadPrompt hasEdits={hasEdits} />
      {editModal}
      <Table preserveQuickFilterOnRowsUpdate {...inventoryTableProps} />
    </InventoryBatchLayout>
  );
};
