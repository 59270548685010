import { GetEventResponse, GetEventsResponse, GetSalesForEventResponse } from 'api/actions';
import { getWarningsForEvent } from 'helpers';
import React, { useContext } from 'react';
import { EventPageSection, EventPageSectionRowId } from './types';
import { AttentionWarningItemType } from 'components/AttentionWarningContentStack';
import { QueryClient } from '@tanstack/react-query';

type EventContextType = {
  event: GetEventResponse['data'] | GetEventsResponse['data'][number];
  loading: boolean;
  warningItems: AttentionWarningItemType<EventPageSection, EventPageSectionRowId>[];
  getRowWarningDescription: (row: EventPageSectionRowId) => string[] | undefined;
  invalidateQueriesHandler: (queryClient: QueryClient) => void | Promise<void>;
  eventSales: GetSalesForEventResponse['data'];
};

export const EventContext = React.createContext<EventContextType>({
  event: {} as GetEventResponse['data'],
  loading: false,
  warningItems: [],
  getRowWarningDescription: () => undefined,
  invalidateQueriesHandler: () => {},
  eventSales: [],
});

export type EventContextProviderProps = Pick<EventContextType, 'event' | 'eventSales' |'loading' | 'invalidateQueriesHandler'> & {
  children: React.ReactNode | ((context: EventContextType) => React.ReactNode);
};

export const EventContextProvider: React.FC<EventContextProviderProps> = props => {
  const { warningItems, getRowWarningDescription } = getWarningsForEvent(props.event);
  const contextValue: EventContextType = {
    ...props,
    warningItems,
    getRowWarningDescription,
  };

  const children = typeof props.children === 'function' ? props.children(contextValue) : props.children;

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => useContext(EventContext);