import { DateObjectType } from 'services';

export type ChartDateRangeType = {
  start: DateObjectType;
  end: DateObjectType;
};

export const enum AnalyticsTimePeriodEnum {
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

export const enum AnalyticsChartTypeEnum {
  bar = 'bar',
  line = 'line',
  pie = 'pie'
}