import { Box, Rating, Typography } from '@mui/material';
import { GetEventFeedbackResponse } from 'api/actions/eventFeedback';
import { EventFeedbackIconField, getEventFeedbackIcon, getSummativeEventFeedback } from 'components/Event/helpers';

type SummativeEventFeedbackItemProps = {
  value?: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const SummativeEventFeedbackItem: React.FC<SummativeEventFeedbackItemProps> = ({ value, icon: Icon }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Icon fontSize="large" color="disabled" />
      <Rating value={value ?? 0} size="large" precision={0.1} readOnly />
      <Typography variant="h6" color={(theme) => theme.palette.grey[500]} ml={0.5}>
        {value && value.toFixed(1)}
      </Typography>
    </Box>
  );
};

export type SummativeEventFeedbackProps = {
  feedback: GetEventFeedbackResponse['data'];
};

export const SummativeEventFeedback: React.FC<SummativeEventFeedbackProps> = ({ feedback }) => {
  const summativeFeedback = getSummativeEventFeedback(feedback);

  const feedbackItems = Object.keys(summativeFeedback).map((key: EventFeedbackIconField) => (
    { id: key, value: summativeFeedback[key as keyof typeof summativeFeedback], icon: getEventFeedbackIcon(key) }
  ));

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
      gap={3}
      mb={3}
    >
      {feedbackItems.map((item) => (
        <Box key={item.id}>
          <SummativeEventFeedbackItem value={item.value} icon={item.icon} />
        </Box>
      ))}
    </Box>
  );
};