import { Box, Typography, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { updateEvent } from 'api/actions';
import { PlaceDefaultEnum } from 'api/resources';
import { useAlertSnackbar } from 'components/AlertSnackbar';
import { useEventContext } from 'components/Event/event.context';
import { EventDaysSummary } from 'components/Event/EventDaysSummary.component';
import { PlaceDistanceAndDurationContent } from 'components/PlaceDistanceAndDurationContent';
import { SectionCard, SectionCardRow } from 'components/SectionCard';
import { WrapWithStatusIndicator } from 'components/StatusIndicator';
import { useEventTeamChangeOptions } from 'queries';
import { DateService } from 'services';

export const EventPreviewContentTeamScheduling = () => {
  const { event, invalidateQueriesHandler } = useEventContext();
  const { data: teamChangeOptions = [] } = useEventTeamChangeOptions(event._id);
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const updateTeamMutation = useMutation({
    mutationFn: async (value: string | null) => {
      return await updateEvent(event._id, { team: value });
    },
    onSuccess: async () => {
      await invalidateQueriesHandler(queryClient);
      snackbar.success('Event team updated');
    },
    onError: () => {
      snackbar.error('Unable to update event team');
    }
  });

  const onTeamChange = (value: string | null) => {
    if ((!value && !event.team) || event.team?._id === value) {
      return;
    }

    updateTeamMutation.mutate(value);
  };

  return (
    <Box mx={2} my={5} height="calc(100vh - 171px)">
      <SectionCard title="">
        <SectionCardRow title="Space Size">{event.spaceSize}</SectionCardRow>
        <SectionCardRow title="Days Count"><EventDaysSummary event={event} /></SectionCardRow>
        <SectionCardRow title="Distance & Duration">
          <PlaceDistanceAndDurationContent
            fromLabel="Studio"
            toLabel="Event"
            routeDetails={{ fromAddress: PlaceDefaultEnum.studio, toAddress: event.place.address }}
            placeMatrix={event.place.fromStudioMatrix}
          />
        </SectionCardRow>
      </SectionCard>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography color="text.secondary">Assign team</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <ToggleButtonGroup
            value={event.team?._id}
            onChange={(_, value) => value && onTeamChange(value)}
            exclusive
            size="small"
            disabled={updateTeamMutation.isLoading}
          >
            {teamChangeOptions.map(option => {
              if (option.isWarehouse) {
                return null;
              }

              return (
                <Tooltip
                  title={option.eventDateConflicts?.map((eventDate) => {
                    return (
                      <Typography key={eventDate.eventDateId} variant="body2">
                        <Typography component="span" color="error" variant="inherit">CONFLICT:</Typography> {eventDate.eventName} - {DateService.getFormattedDate(eventDate.eventDate)}
                      </Typography>
                    );
                  })}
                  key={option.id}
                >
                  <Box>
                    <ToggleButton value={option.id} disabled={!!option.eventDateConflicts}>
                      <WrapWithStatusIndicator status={option.color}>
                        {option.name}
                      </WrapWithStatusIndicator>
                    </ToggleButton>
                  </Box>
                </Tooltip>
              );
            })}
          </ToggleButtonGroup>
          <Typography variant="body2" color="text.secondary">or</Typography>
          <ToggleButton
            value="bank"
            disabled={updateTeamMutation.isLoading}
            onClick={() => onTeamChange(null)}
            size="small"
          >
            Move to bank
          </ToggleButton>
        </Box>
      </Box>
    </Box>
  );
};
