import { useQuery } from '@tanstack/react-query';
import { getEventSalesAnalytics, GetEventSalesAnalyticsInput, getProductionTimelineAnalytics, GetProductionTimelineAnalyticsInput, getSalesAnalytics, GetSalesAnalyticsInput, getSalesTimelineAnalytics, GetSalesTimelineAnalyticsInput } from 'api/actions';
import { QUERY_KEY } from './query-keys';

export const useSalesTimelineAnalytics = (input: GetSalesTimelineAnalyticsInput) => {
  return useQuery(QUERY_KEY.ANALYTICS_SALES_TIMELINE(input), async () => {
    const data = await getSalesTimelineAnalytics(input);

    return data.data;
  });
};

export const useSalesAnalytics = (input: GetSalesAnalyticsInput) => {
  return useQuery(QUERY_KEY.ANALYTICS_SALES(input), async () => {
    const data = await getSalesAnalytics(input);

    return data.data;
  });
};

export const useEventSalesAnalytics = (eventId: string, input: GetEventSalesAnalyticsInput) => {
  return useQuery(QUERY_KEY.ANALYTICS_EVENT_SALES(eventId, input), async () => {
    const data = await getEventSalesAnalytics(eventId, input);

    return data.data;
  });
};

export const useProductionTimelineAnalytics = (input: GetProductionTimelineAnalyticsInput) => {
  return useQuery(QUERY_KEY.ANALYTICS_PRODUCTION_TIMELINE(input), async () => {
    const data = await getProductionTimelineAnalytics(input);

    return data.data;
  });
};
