import { authAxiosRequest } from 'api/axios';
import { AnalyticsTimePeriodEnum } from 'components/AnalyticChart/types';


export type GetSalesTimelineAnalyticsInput = {
  timezone: string;
  timePeriod: AnalyticsTimePeriodEnum;
  series: {
    id: string;
    start?: string;
    end?: string;
    productId?: string;
    userId?: string;
    eventDateId?: string;
    teamId?: string;
  }[];
};

export type SaleTimelineAnalyticsDataType = {
  label: string;
  count: number;
  revenue: number;
};

export type SaleTimelineAnalyticsSeriesType = {
  id: string;
  start: string;
  end: string;
  data: SaleTimelineAnalyticsDataType[];
};

export type GetSalesTimelineAnalyticsResponse = {
  data: SaleTimelineAnalyticsSeriesType[];
};

export const getSalesTimelineAnalytics = async (params: GetSalesTimelineAnalyticsInput) => authAxiosRequest<GetSalesTimelineAnalyticsResponse>('/api/analytics/timeline/sales', { method: 'POST', data: params });

export type GetSalesAnalyticsInput = {
  start: string;
  end: string;
  compareStart?: string;
  compareEnd?: string;
};

export type SaleAnalyticsDatasetType = {
  id: string;
  label: string;
  count: number;
  revenue: number;
  compareCount?: number;
  compareRevenue?: number;
};

export type GetSalesAnalyticsResponse = {
  data: {
    byEvent: SaleAnalyticsDatasetType[];
    byProduct: SaleAnalyticsDatasetType[];
    byUser: SaleAnalyticsDatasetType[];
  };
};

export const getSalesAnalytics = async (params: GetSalesAnalyticsInput) => authAxiosRequest<GetSalesAnalyticsResponse>('/api/analytics/sales', { params });

export type GetEventSalesAnalyticsInput = {
  timezone: string;
  timePeriod: AnalyticsTimePeriodEnum;
};

export type GetEventSalesAnalyticsResponse = {
  data: {
    eventId: string;
    data: SaleTimelineAnalyticsDataType[];
    start: string;
    end: string;
  }[];
};

export const getEventSalesAnalytics = async (eventId: string, params: GetEventSalesAnalyticsInput) => authAxiosRequest<GetEventSalesAnalyticsResponse>(`/api/analytics/events/${eventId}/sales`, { params });


export type GetProductionTimelineAnalyticsInput = {
  timePeriod: AnalyticsTimePeriodEnum;
  timezone: string;

  series: {
    id: string;
    start?: string;
    end?: string;
    productId?: string;
    teamId?: string;
  }[];
};

export type ProductionTimelineAnalyticsDataType = {
  label: string;
  count: number;
};

export type ProductionTimelineAnalyticsSeriesType = {
  id: string;
  start: string;
  end: string;
  data: ProductionTimelineAnalyticsDataType[];
};


export type GetProductionTimelineAnalyticsResponse = {
  data: ProductionTimelineAnalyticsSeriesType[];
};

export const getProductionTimelineAnalytics = async (params: GetProductionTimelineAnalyticsInput) => authAxiosRequest<GetProductionTimelineAnalyticsResponse>('/api/analytics/timeline/production', { method: 'POST', data: params });