import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { DateService } from 'services';
import { useProductionTimelineAnalytics } from 'queries';
import { GetProductionTimelineAnalyticsInput } from 'api/actions';
import { AnalyticsChartTypeEnum, AnalyticsTimePeriodEnum, ChartDateRangeType } from './types';
import { TimelineChart } from './TimelineChart';
import { useAdaptiveTimePeriod } from './hooks/use-adaptive-time-period';

export type ProductionTimelineChartProps = {
  dateRange?: ChartDateRangeType | null;
  compareDateRange?: ChartDateRangeType | null;
  productId?: string;
  teamId?: string;

  initialTimePeriod?: AnalyticsTimePeriodEnum;
  timePeriodOptions?: AnalyticsTimePeriodEnum[];
};

export const ProductionTimelineChart: React.FC<ProductionTimelineChartProps> = ({ dateRange, compareDateRange, productId, teamId, initialTimePeriod, timePeriodOptions }) => {
  const { timePeriod, setTimePeriod, validTimePeriods } = useAdaptiveTimePeriod({ dateRange, initialTimePeriod, timePeriodOptions });
  const [ chartType, setChartType ] = useState<AnalyticsChartTypeEnum>(AnalyticsChartTypeEnum.bar);
  const currentInput: GetProductionTimelineAnalyticsInput['series'][number] = {
    id: 'current',
    productId,
    teamId,
  };

  if (dateRange) {
    currentInput.start = dateRange.start.toISOString();
    currentInput.end = dateRange.end.endOf('day').toISOString();
  }

  const seriesInput = [ currentInput ];

  if (compareDateRange) {
    seriesInput.push({
      id: 'comparison',
      start: compareDateRange.start.toISOString(),
      end: compareDateRange.end.endOf('day').toISOString(),
      productId,
      teamId,
    });
  }

  const { data: productionAnalyticsSeries = [], isLoading } = useProductionTimelineAnalytics({
    timePeriod,
    timezone: DateService.dayjs.tz.guess(),
    series: seriesInput,
  });

  return (
    <TimelineChart
      title="Production"
      chartType={chartType}
      setChartType={setChartType}
      timePeriod={timePeriod}
      setTimePeriod={setTimePeriod}
      timePeriodOptions={validTimePeriods}
      series={productionAnalyticsSeries}
      getSeriesData={series => series.data.map(d => d.count)}
      isLoading={isLoading}
      estSeriesLength={seriesInput.length}
      getLengendChipContent={(s) => (
        <Box ml={0.5} display="flex" alignItems="center" gap={1}>
          <Typography variant="body2" color="text.secondary" fontWeight={500}>{s.data.reduce((acc, d) => acc + d.count, 0)} produced</Typography>
        </Box>
      )}
      getTooltipContent={(data) => [
        {
          content: <Typography fontSize="small" fontWeight={500}>{data.count} produced</Typography>
        }
      ]}
    />
  );
};