import { DateRange, PopoverButton } from 'components';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { compareToEnumHelpers, CompareToShortcutEnum, DateRangeShortcutEnum } from './helpers';
import { Box, Button, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { DateService } from 'services';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { ArrowRightAlt, CheckOutlined } from '@mui/icons-material';

export type CompareToPopoverProps = {
  dateRange: DateRange | DateRangeShortcutEnum;
  compareToDateRange: DateRange | CompareToShortcutEnum | null;
  onChange: (compareToDateRange: DateRange | CompareToShortcutEnum | null) => void;
};

export type CompareToRangeFormInput = { compareToDateRange: DateRange | CompareToShortcutEnum | null };

export const CompareToPopover: React.FC<CompareToPopoverProps> = props => {
  const label = useMemo(() => {
    if (!props.compareToDateRange) {
      return 'No comparison';
    }

    if (typeof props.compareToDateRange === 'string') {
      return `Compare: ${compareToEnumHelpers.getLabel(props.compareToDateRange)}`;
    }


    return `Compare: ${DateService.getFormattedDateRange(props.compareToDateRange.start, props.compareToDateRange.end)}`;
  }, [  props.compareToDateRange ]);

  return (
    <PopoverButton label={label} id="daterange-com">
      {({ handleClose }) => {
        return (
          <Formik<CompareToRangeFormInput>
            initialValues={{ compareToDateRange: props.compareToDateRange }}
            onSubmit={(values) => props.onChange(values.compareToDateRange)}
          >
            {formik => {
              const dateRange = compareToEnumHelpers.getDateRange(props.dateRange, formik.values.compareToDateRange);
              const isNoComparison = formik.values.compareToDateRange === null;

              return (
                <div>
                  <Box>
                    <Box display="flex" height="100%">
                      <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        width="200px"
                        borderRight={theme => `1px solid ${theme.palette.divider}`}
                        boxShadow={theme => `inset 0 -16px 16px -16px ${theme.palette.divider}`}
                      >
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          bottom={0}
                          width="100%"
                          display="flex"
                          flexDirection="column"
                        >
                          <Box flex={1} overflow="auto" display="flex" flexDirection="column" gap={1}>
                            <List>
                              <ListItemButton
                                selected={isNoComparison}
                                onClick={() => formik.setValues({ compareToDateRange: null })}
                                sx={{ position: 'relative' }}
                              >
                                <ListItemText
                                  primary={compareToEnumHelpers.getLabel(null)}
                                  primaryTypographyProps={{ fontWeight: isNoComparison ? 500 : 400, fontSize: 'small' }}
                                />
                                {isNoComparison && (
                                  <Box position="absolute" right={0} pr={2} height="100%" display="flex" alignItems="center">
                                    <CheckOutlined fontSize="small" color="primary" />
                                  </Box>
                                )}
                              </ListItemButton>
                              {compareToEnumHelpers.enumValues.map(shortcut => {
                                const isSelected = typeof formik.values.compareToDateRange === 'string' && formik.values.compareToDateRange === shortcut;

                                return (
                                  <ListItemButton
                                    selected={isSelected}
                                    key={shortcut}
                                    onClick={() => formik.setValues({ compareToDateRange: shortcut })}
                                    sx={{ position: 'relative' }}
                                  >
                                    <ListItemText
                                      primary={compareToEnumHelpers.getLabel(shortcut)}
                                      primaryTypographyProps={{ fontWeight: isSelected ? 500 : 400, fontSize: 'small' }}
                                    />
                                    {isSelected && (
                                      <Box position="absolute" right={0} pr={2} height="100%" display="flex" alignItems="center">
                                        <CheckOutlined fontSize="small" color="primary" />
                                      </Box>
                                    )}
                                  </ListItemButton>
                                );
                              })}
                            </List>
                          </Box>
                        </Box>
                      </Box>

                      <Box>
                        <Box py={1.5} pt={2} display="flex" alignItems="center">
                          <Box flex={1} px={2} textAlign="center">
                            <Typography fontWeight={500} fontSize="large">{dateRange?.start.format('MMMM Do, YYYY') ?? '___'}</Typography>
                          </Box>
                          <Box display="flex" color="text.secondary">
                            <ArrowRightAlt fontSize="large" color="inherit" />
                          </Box>
                          <Box flex={1} px={2} textAlign="center">
                            <Typography fontWeight={500} fontSize="large">{dateRange?.end.format('MMMM Do, YYYY') ?? '___'}</Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <StaticDateRangePicker
                          value={[ dateRange?.start ?? null, dateRange?.end ?? null ]}
                          onChange={(value) => value[0] && value[1] && formik.setValues({ compareToDateRange: { start: value[0], end: value[1] } })}
                          slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
                          calendars={2}
                        />
                      </Box>
                    </Box>

                  </Box>

                  <Divider />
                  <Box display="flex" justifyContent="flex-end" py={2} gap={2} px={2}>
                    <Button onClick={handleClose} size="small" color="inherit">Cancel</Button>
                    <Button
                      onClick={() => {
                        formik.handleSubmit();
                        handleClose();
                      }}
                      disabled={!formik.isValid} size="small" variant="contained"
                    >
                      Apply
                    </Button>
                  </Box>
                </div>
              );
            }}
          </Formik>
        );
      }}
    </PopoverButton>
  );
};