import { Box } from '@mui/material';
import { CompareToPopover,compareToEnumHelpers, dateRangeShortcutEnumHelpers, CompareToShortcutEnum, DateRange, DateRangePickerPopover, DateRangeShortcutEnum, PageContentContainer, PageContentHeader, SalesTimelineChart, SalesChart } from 'components';
import { useState } from 'react';

import { useLocalStorage } from 'hooks';
import { DateService } from 'services';
import { ProductionTimelineChart } from 'components/AnalyticChart/ProductionTimelineChart';

export const Analytic: React.FC = () => {
  const dateRangeLocalStorage = useLocalStorage<{ start: string; end: string } | DateRangeShortcutEnum>('analytics-date-range');
  const compareDateRangeLocalStorage = useLocalStorage<{ start: string; end: string } | CompareToShortcutEnum | null>('analytics-compare-date-range');
  const initialDateRange = dateRangeLocalStorage.getResource(DateRangeShortcutEnum.today);
  const [ dateRange, setDateRange ] = useState<DateRange | DateRangeShortcutEnum>(typeof initialDateRange === 'string' ? initialDateRange : { start: DateService.dayjs(initialDateRange.start), end: DateService.dayjs(initialDateRange.end) });
  const initialCompareDateRange = compareDateRangeLocalStorage.getResource(null);
  const [ compareDateRange, setCompareDateRange ] = useState<DateRange | CompareToShortcutEnum | null>(() => {
    if (initialCompareDateRange) {
      return typeof initialCompareDateRange === 'string' ? initialCompareDateRange : { start: DateService.dayjs(initialCompareDateRange.start), end: DateService.dayjs(initialCompareDateRange.end) };
    }

    return null;
  });

  const onDateRangeChange = (dateRangeOrShortcut: DateRange | DateRangeShortcutEnum) => {
    dateRangeLocalStorage.setResource(dateRangeOrShortcut as any);
    setDateRange(dateRangeOrShortcut);
  };

  const onCompareDateRangeChange = (compareToRangeOrShortcut: DateRange | CompareToShortcutEnum | null) => {
    compareDateRangeLocalStorage.setResource(compareToRangeOrShortcut as any);
    setCompareDateRange(compareToRangeOrShortcut);
  };

  const range = dateRangeShortcutEnumHelpers.getDateRange(dateRange);
  const compareRange = compareToEnumHelpers.getDateRange(dateRange, compareDateRange);

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          sticky
          title="Analytics"
          leftActions={(
            <Box display="flex" gap={1} flexWrap="wrap">
              <DateRangePickerPopover dateRange={dateRange} onChange={onDateRangeChange}/>
              <CompareToPopover dateRange={dateRange} compareToDateRange={compareDateRange} onChange={onCompareDateRangeChange} />
            </Box>
          )}
        />
      )}
    >
      <SalesTimelineChart
        dateRange={range}
        compareDateRange={compareRange}
      />
      <Box my={2} display="grid" gridTemplateColumns={{ md: 'repeat(2,1fr)', lg: 'repeat(3,1fr)' }} gap={2}>
        <SalesChart dateRange={range} type="byUser" compareDateRange={compareRange} />
        <SalesChart dateRange={range} type="byEvent" compareDateRange={compareRange} />
        <SalesChart dateRange={range} type="byProduct" compareDateRange={compareRange} />
      </Box>
      <ProductionTimelineChart
        dateRange={range}
        compareDateRange={compareRange}
      />
    </PageContentContainer>
  );
};
