import { useState } from 'react';
import { InventoryTableEditable } from './components/InventoryTableEditable.component';
import { EditModeToggler, PageContentContainer, PageContentHeader, TableActionsBar } from 'components';
import { InventoryTable } from './components/InventoryTable.component';
import { useCurrentUser } from 'contexts';
import { UserEmployeeRoleEnum } from 'api/resources';

export const InventoryAdjustments = () => {
  const me = useCurrentUser();
  const [ isEditMode, setIsEditMode ] = useState(false);

  return (
    <PageContentContainer header={<PageContentHeader title="Inventory Adjustments" />}>
      <TableActionsBar
        editModeToggler={me.isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ], true) ? <EditModeToggler isEditMode={isEditMode} setIsEditMode={setIsEditMode} /> : undefined}
      />
      {isEditMode ? <InventoryTableEditable /> : <InventoryTable />}
    </PageContentContainer>
  );
};