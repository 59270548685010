import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetSaleResponse, GetSalesInput, getSale, getSales, getSalesForEvent, getEventsSales, getStorefrontsSales } from 'api/actions';
import { QUERY_KEY } from './query-keys';

export const useEventsSales = (input?: GetSalesInput, enabled = true) => {
  return useQuery(QUERY_KEY.EVENTS_SALES(input), async () => {
    const response = await getEventsSales(input);

    return response.data;
  }, { enabled });
};

export const useStorefrontsSales = (input?: GetSalesInput) => {
  return useQuery(QUERY_KEY.STORE_FRONTS_SALES(input), async () => {
    const response = await getStorefrontsSales(input);

    return response.data;
  });
};

export const useSales = (input?: GetSalesInput, enabled = true) => {
  return useQuery(QUERY_KEY.SALES(input), async () => {
    const response = await getSales(input);

    return response.data;
  }, { enabled });
};

export const useSalesForEvent = (eventId: string, enabled?: boolean) => {
  return useQuery(QUERY_KEY.SALES_FOR_EVENT(eventId), async () => {
    const response = await getSalesForEvent(eventId);

    return response.data;
  }, { enabled });
};

export const useSale = (id: string, opts?: UseQueryOptions<GetSaleResponse['data']>) => {
  return useQuery(QUERY_KEY.SALE(id), async () => {
    const response = await getSale(id);

    return response.data;
  }, opts);
};
