import { InventoryTableSettingsModal, InventoryTableModeInput, InventoryTableShowStockMetaInput, InventoryTableStandardFiltersInput, Table, InventoryTableTeamColumnsInput, useInventoryTable, prepareInventoryTableEditsToSave, SaveInventoryEditsParams, InventoryTableEditableMode, StandardInventoryTableFilters, prepareInventoryTableProductionStockEditsToSave } from 'components';
import { StockTypeInput } from 'components/InventoryEditsTable/SettingsModal/StockTypeInput.component';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { useProductStocksQuantityMutation, useProductProductionStocksQuantityMutation } from 'pages/Production/mutations';
import { Fragment, useMemo, useState } from 'react';
import { UnknownEnum } from 'types';

export const InventoryTableEditable: React.FC = () => {
  const { getProductsSortedAlphabetically, teams } = useProductsPageContext();
  const me = useCurrentUser();
  const regularStocksSaveMutation = useProductStocksQuantityMutation();
  const productionStocksSaveMutation = useProductProductionStocksQuantityMutation();
  const products = useMemo(getProductsSortedAlphabetically, [ getProductsSortedAlphabetically ]);
  const [ stockType, setStockType ] = useState<'production' | 'regular'>('regular');

  const onSave = async (params: SaveInventoryEditsParams) => {
    if (stockType === 'production') {
      await productionStocksSaveMutation.mutateAsync({ stocks: prepareInventoryTableProductionStockEditsToSave(params), userId: me._id });
    } else {
      await regularStocksSaveMutation.mutateAsync({ stocks: prepareInventoryTableEditsToSave(params), userId: me._id });
    }
  };
  const getDisplayStandardFilters = (mode: InventoryTableEditableMode | UnknownEnum.unknown): StandardInventoryTableFilters[] => {
    if (mode === UnknownEnum.unknown) {
      return [];
    }

    if (mode === InventoryTableEditableMode.transfer) {
      return [ 'showNotAvailableAtEvents', 'showNotAvailableAtEvents', 'showInNotNeeded', 'showNoOutStock', 'showOutNotOverstocked' ];
    }

    return [ 'showNotAvailableAtEvents', 'showNotAvailableAtEvents' ];
  };
  const {
    inventoryTableProps,
    editModal,
    setSettingsModalOpen,
    settingsModalOpen,
    teamIds,
    setTeamIds,
    transferConfig,
    setTransferConfig,
    showStockMeta,
    setShowStockMeta,
    mode,
    setMode,
    standardFilters,
    setStandardFilters,
    hasEdits,
  } = useInventoryTable({
    products,
    teams,
    getStockQuantity: (stock) => stockType === 'production' ? stock.productionQuantity : stock.quantity,
    onSaveAll: onSave,
    onSaveRow: onSave,
    settingsArgs: {
      initialSettingsModalOpen: true,
      initialMode: me.isAdmin ? undefined : InventoryTableEditableMode.set,
      getDisplayStandardFilters,
    },
    teamColumnsArgs: {
      getTeamColumnHeaderSecondary: () => stockType === 'production' ? 'production stock' : null,
    }
  });

  return (
    <Fragment>
      {settingsModalOpen && (
        <InventoryTableSettingsModal
          onClose={() => setSettingsModalOpen(false)}
          teamSelectionInput={(
            <InventoryTableTeamColumnsInput
              mode={mode}
              getTeamDisabled={(teamId) => {
                if (hasEdits) {
                  return true;
                }

                if (stockType === 'production') {
                  return !teams.find(team => team._id === teamId)?.isWarehouse;
                }

                return false;
              }}
              teams={teams}
              selectedTeamIds={teamIds}
              setSelectedTeamIds={setTeamIds}
              transferConfig={transferConfig}
              setTransferConfig={setTransferConfig}
            />
          )}
          showStockMetaInput={<InventoryTableShowStockMetaInput showStockMeta={showStockMeta} setShowStockMeta={setShowStockMeta} />}
          stockTypeInput={<StockTypeInput stockType={stockType} setStockType={setStockType} />}
          modeInput={(
            <InventoryTableModeInput
              disabled={hasEdits || !me.isAdmin}
              mode={mode}
              setMode={setMode}
              allowedModes={stockType === 'production' ? [ InventoryTableEditableMode.set, InventoryTableEditableMode.unary ] : undefined}
            />
          )}
          filterInputs={mode === UnknownEnum.unknown ? undefined : (
            <InventoryTableStandardFiltersInput
              displayFilters={getDisplayStandardFilters(mode)}
              standardFilters={standardFilters}
              setStandardFilters={setStandardFilters}
              teams={teams}
              transferConfig={transferConfig}
            />
          )}
        />
      )}
      {editModal}
      <Table preserveQuickFilterOnRowsUpdate {...inventoryTableProps} />
    </Fragment>
  );
};